import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import Media from "react-media";

import Main from "layouts/Main";

import Login from "views/Login/Index";
import Dashboard from "views/Dashboard/Index";
import Messages from "views/Messages/Index";
import Payments from "views/Payments/Index";
import Notifications from "views/Notifications/Index";
import ChangeLog from "views/ChangeLog/Index";
import PaperBuilder from "views/PaperBuilder/Index";
import FormBuilder from "views/FormBuilder/Index";
import Test from "views/Test/Index";

import PrivateRoute from "globalComponents/ProtectedRoute";

function App() {
    const mainRoutes = [
        {
            title: "Dashboard",
            path: "/dashboard",
            component: Dashboard,
        },
        {
            title: "Messages",
            path: "/messages",
            component: Messages,
        },
        {
            title: "Payments",
            path: "/payments",
            component: Payments,
        },
        {
            title: "Notifications",
            path: "/notifications",
            component: Notifications,
        },
        {
            title: "Notifications",
            path: "/notifications/:notification_id",
            component: Notifications,
        },
        {
            title: "Change Log",
            path: "/changeLog",
            component: ChangeLog,
        },
        {
            title: "Change Log",
            path: "/changeLog/:change_log_id",
            component: ChangeLog,
        },
        {
            title: "Paper Builder",
            path: "/paperBuilder",
            component: PaperBuilder,
        },
        {
            title: "Form Builder",
            path: "/formBuilder",
            component: FormBuilder,
        },
        {
            title: "Test",
            path: "/test",
            component: Test,
        },
    ];

    return (
        <Media
            queries={{
                xxs: "(max-width: 320px)", // iphone 5s (small)
                xs: "(max-width: 480px)", // 5.5 inches phones (medium)
                sm: "(max-width: 576px)", // 5.5 inches phones (medium)
                md: "(max-width: 768px)", // tablet portrait
                lg: "(max-width: 992px)", // tablet portrait
                xl: "(max-width: 1200px)", // tablet landscape
                xxl: "(max-width: 1600px)", //desktops
            }}
        >
            {(matches) => (
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Redirect from="/" to="/login" />
                        </Route>
                        <Route path="/login" component={Login} />
                        <Route
                            path="/not-found"
                            component={() => <h1>404 not found</h1>}
                        />
                        <Main
                            breakpoint={{
                                isSmallMobile: matches.xxs,
                                isNormalMobile: matches.xs || matches.sm,
                                isTabletPortrait: matches.md || matches.lg,
                                isTabletLandscape: matches.xl,
                            }}
                        >
                            <Switch>
                                {mainRoutes.map((route, index) => (
                                    <PrivateRoute
                                        key={index}
                                        exact
                                        path={route.path}
                                        component={route.component}
                                        title={route.title}
                                        breakpoint={{
                                            isSmallMobile: matches.xxs,
                                            isNormalMobile:
                                                matches.xs || matches.sm,
                                            isTabletPortrait:
                                                matches.md || matches.lg,
                                            isTabletLandscape: matches.xl,
                                        }}
                                    />
                                ))}
                                <Route
                                    render={() => <Redirect to="/not-found" />}
                                />
                            </Switch>
                        </Main>
                    </Switch>
                </Router>
            )}
        </Media>
    );
}

export default App;
