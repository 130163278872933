import React, { useEffect } from "react";
import { Form, Input, Select, InputNumber, Button, Checkbox } from "antd";

const FormFormProperties = ({ formProperties, onFinish }) => {
    const [form] = Form.useForm(null);

    const { Option } = Select;
    const { TextArea } = Input;

    useEffect(() => {
        form.setFieldsValue({
            labelCol: formProperties.layout.labelCol.span,
            wrapperCol: formProperties.layout.wrapperCol.span,
            showSubmitButton: formProperties.submitButton.show,
            buttonSize: formProperties.submitButton.buttonSize,
            containerStyle: formProperties.submitButton.containerStyle,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formProperties]);

    const handleFormFinish = (values) => {
        onFinish({
            layout: {
                labelCol: { span: values.labelCol },
                wrapperCol: { span: values.wrapperCol },
            },
            submitButton: {
                show: values.showSubmitButton,
                size: values.buttonSize,
                containerStyle: values.containerStyle,
            },
        });
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    return (
        <Form {...layout} form={form} onFinish={handleFormFinish}>
            <Form.Item
                label="Label Col Span"
                name="labelCol"
                rules={[{ required: true, message: "Please input row name!" }]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                label="Wrapper Col Span"
                name="wrapperCol"
                rules={[{ required: true, message: "Please input row name!" }]}
            >
                <InputNumber />
            </Form.Item>
            <Form.Item
                {...tailLayout}
                name="showSubmitButton"
                valuePropName="checked"
            >
                <Checkbox>Show Submit Button</Checkbox>
            </Form.Item>
            <Form.Item label="Submit Button Size" name="buttonSize">
                <Select>
                    <Option value="small">Small</Option>
                    <Option value="medium">Medium</Option>
                    <Option value="large">Large</Option>
                </Select>
            </Form.Item>
            <Form.Item label="Container Style" name="containerStyle">
                <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="css code"
                />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FormFormProperties;
