import React, { useState, useEffect } from "react";
import { Button, Form, Input } from "antd";

const FormEditRowName = ({ data, onSubmit }) => {
    const [formData, setFormData] = useState({
        id: "",
        name: "",
    });

    useEffect(() => {
        if (data) {
            setFormData({
                id: data.id,
                name: data.name,
            });
        }
    }, [data]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        if (formData.name.trim() !== "") {
            onSubmit(formData);
        }
    };

    return (
        <Form>
            <Form.Item label="Row Name">
                <Input
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                />
            </Form.Item>
            <Button type="primary" onClick={handleSubmit}>
                OK
            </Button>
        </Form>
    );
};

export default FormEditRowName;
