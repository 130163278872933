import {
    GLOBAL_STATE_FETCHING_START,
    GLOBAL_STATE_FETCHING_END,
    REQUEST_ERROR,
} from "./system.types";

export const startStateFetching = () => (dispatch) => {
    dispatch({
        type: GLOBAL_STATE_FETCHING_START,
    });
};

export const endStateFetching = () => (dispatch) => {
    dispatch({
        type: GLOBAL_STATE_FETCHING_END,
    });
};

export const setRequestError = ({ errorMessage, errorSubMessage }) => (
    dispatch
) => {
    if (errorSubMessage === "Request failed with status code 401") {
        localStorage.removeItem("token");
        window.location = `/`;
    } else {
        dispatch({
            type: REQUEST_ERROR,
            payload: { errorMessage, errorSubMessage },
        });
    }
};
