import React, { useState } from "react";
import { InputNumber, Table, Button } from "antd";

const FormRules = ({ onSubmit }) => {
    const [rules, setRules] = useState([
        { id: 1, rule: "required", value: true },
        { id: 2, rule: "min", value: 10 },
        { id: 3, rule: "max", value: 20 },
    ]);
    const [selectedRules, setSelectedRules] = useState([]);

    const handleRuleChange = (ruleId, value) => {
        const newRules = rules.map((rule) => {
            if (rule.id === ruleId) {
                return {
                    ...rule,
                    value,
                };
            }
            return rule;
        });
        setRules(newRules);
    };

    const rulesOk = () => {
        const rules = selectedRules.map((selectedRule) => {
            return { [selectedRule.rule]: selectedRule.value };
        });
        onSubmit(rules);
    };

    const columns = [
        {
            title: "Rule",
            dataIndex: "rule",
            render: (text, record) => record.rule,
        },
        {
            title: "Value",
            dataIndex: "value",
            render: (text, record) => {
                const { id, rule, value } = record;

                switch (rule) {
                    case "required":
                        return "true";
                    case "min":
                        return (
                            <InputNumber
                                defaultValue={value}
                                onChange={(value) =>
                                    handleRuleChange(id, value)
                                }
                            />
                        );
                    case "max":
                        return (
                            <InputNumber
                                defaultValue={value}
                                onChange={(value) =>
                                    handleRuleChange(id, value)
                                }
                            />
                        );
                    default:
                        return null;
                }
            },
        },
    ];

    return (
        <>
            <Table
                rowSelection={{
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRules(selectedRows);
                    },
                }}
                rowKey="id"
                columns={columns}
                dataSource={rules}
                pagination={false}
            />
            <div style={{ marginTop: 16, textAlign: "center" }}>
                <Button type="primary" onClick={rulesOk}>
                    OK
                </Button>
            </div>
        </>
    );
};

export default FormRules;
