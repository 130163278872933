import React, { useState, useEffect } from "react";
import {
    Table,
    Button,
    Divider,
    Row,
    Col,
    Card,
    Popconfirm,
    message,
    Empty,
} from "antd";
import { MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import FormItemProperties from "./Components/FormItemProperties";

import { generateString } from "helpers/randomStringGenerator";

const SortableItem = sortableElement((props) => (
    <tr className="ant-table-row" {...props} />
));
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "move", color: "#999" }} />
));

const FormManageColItems = ({ formRows, selectedFormRowCol, onFinish }) => {
    const [colFormItems, setColFormItems] = useState([]);
    const [selectedColFormItem, setSelectedColFormItem] = useState(null);
    const [selectedTableRowKeys, setSelectedTableRowKeys] = useState([]);

    const [allRowColFormItems, setAllRowColFormItems] = useState({});

    const [
        isFormItemPropertiesVisible,
        setIsFormItemPropertiesVisibile,
    ] = useState(false);

    useEffect(() => {
        const allRowColFormItems = {};
        formRows.forEach((formRow) => {
            formRow.cols.forEach((formRowCol) => {
                formRowCol.formItems.forEach((formItem) => {
                    allRowColFormItems[formItem.name] = 1;
                });
            });
        });
        setAllRowColFormItems(allRowColFormItems);
        setColFormItems(selectedFormRowCol.formItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFormRowCol]);

    const toggleFormItemPropertiesVisible = () => {
        setIsFormItemPropertiesVisibile(!isFormItemPropertiesVisible);
    };

    const addItem = () => {
        const itemName = `name${colFormItems.length + 1}`;
        const isNameExist = allRowColFormItems[itemName];
        if (isNameExist) {
            message.info(
                "Unabled to add item. It will lead to name duplication."
            );
        } else {
            setColFormItems([
                ...colFormItems,
                {
                    id: generateString(5, "i"),
                    type: "input",
                    name: itemName,
                    label: `Name${colFormItems.length + 1}`,
                    value: "",
                    options: "",
                    placeholder: "",
                    rules: [],
                    transform: "normal",
                    offset: 0,
                    containerStyle: "",
                },
            ]);
            setAllRowColFormItems({
                ...allRowColFormItems,
                [itemName]: 1,
            });
            setSelectedColFormItem(null);
            setSelectedTableRowKeys([]);
        }
    };

    const deleteFormItem = (formItemName) => {
        const newColFormItems = colFormItems.filter(
            (colFormItem) => colFormItem.name !== formItemName
        );
        const allRCFI = { ...allRowColFormItems };
        delete allRCFI[formItemName];
        setColFormItems(newColFormItems);
        setAllRowColFormItems(allRCFI);
        setSelectedColFormItem(null);
    };

    // const sortOk = () => {
    //     onOk(formItems);
    // };

    const handleFormItemPropertiesSubmit = (newFormItem) => {
        //check name duplication in entrie form rows

        const isNameExist = allRowColFormItems[newFormItem.name];

        if (isNameExist && selectedColFormItem.name !== newFormItem.name) {
            message.info(
                "Unabled to saved item. It will lead to name duplication."
            );
        } else {
            const newColFormItems = colFormItems.map((colFormItem) => {
                if (colFormItem.name === selectedColFormItem.name) {
                    return { ...colFormItem, ...newFormItem };
                }
                return colFormItem;
            });
            setSelectedColFormItem({ ...selectedColFormItem, ...newFormItem });
            const allRCFI = { ...allRowColFormItems };
            delete allRCFI[selectedColFormItem.name];
            setColFormItems(newColFormItems);
            setAllRowColFormItems(allRCFI);
        }
    };

    const formRowColItemsOk = () => {
        onFinish(colFormItems);
    };

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        //const { dataSource } = this.state;
        if (oldIndex !== newIndex) {
            const newRows = arrayMove(
                [].concat(colFormItems),
                oldIndex,
                newIndex
            ).filter((el) => !!el);
            setColFormItems(newRows);
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            lockAxis="y"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = colFormItems.findIndex(
            (x) => x.id === restProps["data-row-key"]
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Type",
            dataIndex: "type",
            className: "drag-visible cursorPointer",
        },
        {
            title: "Name",
            dataIndex: "name",
            className: "drag-visible cursorPointer",
        },
        {
            title: "Label",
            dataIndex: "label",
            className: "drag-visible cursorPointer",
            render: (text) => text.trim() || "-",
        },
        {
            title: "Acton",
            key: "action",
            className: "cursorPointer",
            width: 50,
            render: (text, record) => {
                return (
                    <Popconfirm
                        title="Delete item?"
                        onConfirm={() => deleteFormItem(record.name)}
                        //onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger size="small">
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                );
            },
        },
    ];

    return (
        <div>
            <Row gutter={16}>
                <Col span={14}>
                    <Row type="flex" justify="space-between">
                        <Col>Col Name: {selectedFormRowCol.name}</Col>
                        <Col>
                            <Button type="primary" onClick={addItem}>
                                Add Input Form Item
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                    <p>Use handle and drag to arrange rows</p>
                    <Table
                        pagination={false}
                        dataSource={colFormItems}
                        columns={columns}
                        rowKey="id"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                        rowSelection={{
                            type: "radio",
                            selectedRowKeys: selectedTableRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedColFormItem(selectedRows[0]);
                                setSelectedTableRowKeys(selectedRowKeys);
                                toggleFormItemPropertiesVisible();
                            },
                        }}
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: (event) => {
                        //             setSelectedColFormItem(record);
                        //         }, // double click row
                        //     };
                        // }}
                    />

                    {/*  */}

                    <Divider />
                    <div style={{ textAlign: "center" }}>
                        <Button type="primary" onClick={formRowColItemsOk}>
                            Save Form Items
                        </Button>
                    </div>
                </Col>
                <Col span={10}>
                    <Card title="Properties">
                        {selectedColFormItem ? (
                            <FormItemProperties
                                selectedColFormItem={selectedColFormItem}
                                onSubmit={handleFormItemPropertiesSubmit}
                            />
                        ) : (
                            <Empty description="No selected Item" />
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default FormManageColItems;
