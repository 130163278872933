import React, { useState, useEffect } from "react";
import { Tooltip, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import he from "he";
import ReactHtmlParser from "react-html-parser";

const Conversations = (props) => {
    const {
        currentUserID,
        messages,
        loading,
        moveScrollToBottomWatcher,
        moveScrollWatcher,
        onScrolledToTop,
    } = props;

    const [lastScrollPos, setLastScrollPos] = useState(0);
    const [isLoadingNewMessages, setIsLoadingNewMessages] = useState(false);

    let divRef = React.createRef();

    useEffect(() => {
        scrollToBottom();
        setLastScrollPos(divRef.scrollHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveScrollToBottomWatcher]);

    useEffect(() => {
        if (moveScrollWatcher !== 0) {
            const scrollHeight = divRef.scrollHeight;

            divRef.scrollTop = scrollHeight - lastScrollPos;
            setLastScrollPos(divRef.scrollHeight);
            setIsLoadingNewMessages(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveScrollWatcher]);

    const replaceString = (message) => {
        message = he.encode(message, {
            useNamedReferences: true,
        });
        message = message.replace(/\n/g, "<br/>");
        return message;
    };

    function scrollToBottom() {
        const scrollHeight = divRef.scrollHeight;
        const height = divRef.clientHeight;
        const maxScrollTop = scrollHeight - height;
        divRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    const handleScroll = (e) => {
        let element = e.target;
        if (element.scrollTop < 1) {
            if (!isLoadingNewMessages && messages.length !== 0) {
                setIsLoadingNewMessages(true);
                onScrolledToTop();
            }
        }
    };

    const height = 400;
    let lastMessageSenderID = "";

    return (
        <Spin tip="loading messages..." spinning={loading}>
            <div
                style={{
                    height: `calc(100vh - ${height}px)`,
                    backgroundColor: "#f9f9f9",
                    padding: 10,
                    overflowY: "auto",
                    width: "100%",
                }}
                className="cwaChat"
                onScroll={handleScroll}
                ref={(div) => {
                    divRef = div;
                }}
            >
                {isLoadingNewMessages && (
                    <div style={{ textAlign: "center" }}>
                        <LoadingOutlined />
                    </div>
                )}
                {messages.length !== 0 &&
                    messages.map((messageData, index) => {
                        const {
                            sender_id,
                            message,
                            datetime_sent,
                        } = messageData;

                        const className =
                            sender_id === currentUserID ? "rightM" : "leftM";

                        let marginTop = 0;
                        if (index === 0) {
                            lastMessageSenderID = sender_id;
                        } else {
                            if (lastMessageSenderID === sender_id) {
                                marginTop = 4;
                            } else {
                                marginTop = 12;
                            }
                            lastMessageSenderID = sender_id;
                        }

                        return (
                            <Tooltip
                                title={moment(datetime_sent).format("LLLL")}
                                placement="right"
                                key={index}
                            >
                                <div
                                    className={className}
                                    style={{ marginTop }}
                                >
                                    <span>
                                        {ReactHtmlParser(
                                            replaceString(message)
                                        )}
                                    </span>
                                </div>
                            </Tooltip>
                        );
                    })}
            </div>
        </Spin>
    );
};

export default Conversations;
