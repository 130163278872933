import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    startStateFetching,
    endStateFetching,
    setRequestError,
} from "redux/system/system.actions";
import { setClinics } from "redux/clinics/clinics.actions";
import { Layout, Result, Button, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import http from "services/httpService";
import { apiUrl, socketUrl } from "config.json";

import openSocket from "socket.io-client";

/* Main Socket Instance */

const socket = openSocket(socketUrl, {
    query: `token=${localStorage.getItem("token")}`,
});

socket.on("connect", () => {
    console.log("Connected to server!");
    socket.emit("addUser", "support", "support");
});

socket.on("disconnect", () => {
    console.log("Disconnected to server!");
});

/* End Main Socket Instance */

const Main = ({
    breakpoint,
    children,
    isStateFetching,
    isRequestError,
    requestErrorMsg,
    requestErrorSubMsg,
    startStateFetching,
    endStateFetching,
    setClinics,
    setRequestError,
    ...props
}) => {
    const { Content } = Layout;

    let pageTitle = "";
    children.props.children[0].forEach((views) => {
        let viewsPath = views.props.path;
        viewsPath = viewsPath.replace(/\/:.*/g, "");

        if (props.location.pathname.indexOf(viewsPath) !== -1) {
            pageTitle = views.props.title;
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                startStateFetching(true);
                const clinincResponse = await http.get(`${apiUrl}/clinics`);
                setClinics(clinincResponse.data.clinics);
                endStateFetching(true);
            } catch (error) {
                setRequestError({
                    errorMessage: "Something went wrong :(",
                    errorSubMessage: error.message,
                });
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var pageTitle = document.title;
        function visibilitychange(e) {
            var isPageActive = !document.hidden;
            if (isPageActive) {
                document.title = pageTitle;
            }
        }

        window.addEventListener("visibilitychange", visibilitychange);

        return () => {
            window.removeEventListener("visibilitychange", visibilitychange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isRequestError ? (
        <Result
            status="500"
            title={requestErrorMsg}
            subTitle={requestErrorSubMsg}
            extra={
                <Button
                    type="primary"
                    onClick={() => window.location.reload(true)}
                >
                    Refresh Page
                </Button>
            }
        />
    ) : isStateFetching ? (
        <Row>
            <Col span={6}></Col>
            <Col span={12}>
                <div style={{ textAlign: "center", marginTop: 120 }}>
                    <h2>
                        <LoadingOutlined /> Loading ...
                    </h2>
                </div>
            </Col>
            <Col span={6}></Col>
        </Row>
    ) : (
        <Layout>
            <Sidebar activeMenu={pageTitle} breakpoint={breakpoint} />
            <Layout style={{ minHeight: "calc(100vh)" }}>
                <Topbar title={pageTitle} breakpoint={breakpoint} />
                <Content style={{ margin: "16px 16px 0 16px" }}>
                    {children}
                </Content>
                <Footer />
            </Layout>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    isStateFetching: state.System.isStateFetching,
    isRequestError: state.System.isRequestError,
    requestErrorMsg: state.System.requestErrorMsg,
    requestErrorSubMsg: state.System.requestErrorSubMsg,
});

const mapDispatchToProps = (dispatch) => ({
    startStateFetching: () => dispatch(startStateFetching()),
    endStateFetching: () => dispatch(endStateFetching()),
    setRequestError: (data) => dispatch(setRequestError(data)),
    setClinics: (clinics) => dispatch(setClinics(clinics)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
export { socket };
