import React, { useState, useEffect } from "react";
import { Card, Form, InputNumber, Select, Button } from "antd";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";

const PaperProperties = ({ paperProps, onChange }) => {
    const [selectedPaper, setSelectedPaper] = useState({
        id: null,
        type: "",
        width: 0,
        height: 0,
        style: "/* no paper selected */",
    });

    const { Option } = Select;

    const papers = [
        {
            id: 1,
            type: "A5 Portrait",
            width: 559,
            height: 793,
            style: "background-color: #fff;",
        },
        {
            id: 2,
            type: "A4 Portrait",
            width: 793,
            height: 1122,
            style: "background-color: #fff;",
        },
        {
            id: 3,
            type: "Letter Portrait",
            width: 816,
            height: 1056,
            style: "background-color: #fff;",
        },
    ];

    useEffect(() => {
        if (paperProps) {
            setSelectedPaper(paperProps);
        } else {
            setSelectedPaper({
                id: null,
                type: "",
                width: 0,
                height: 0,
                style: "/* no paper selected */",
            });
        }
    }, [paperProps]);

    const handleSelectChange = (value) => {
        const selectedPaper = papers.find((paper) => paper.id === value);
        setSelectedPaper(selectedPaper);
    };

    const handleInputNumberChange = (name, value) => {
        const newSelectedPaper = { ...selectedPaper, [name]: value };
        setSelectedPaper(newSelectedPaper);
    };

    const handleStyleChange = (value) => {
        const newSelectedPaper = { ...selectedPaper, style: value };
        setSelectedPaper(newSelectedPaper);
    };

    const handlePaperPropsApply = () => {
        if (selectedPaper.id) {
            onChange(selectedPaper);
        }
    };

    return (
        <Card title="Paper" style={{ marginBottom: 16 }}>
            <Form
                layout="horizontal"
                labelCol={{
                    xl: { span: 4 },
                    lg: { span: 5 },
                }}
                wrapperCol={{
                    xl: { span: 20 },
                    lg: { span: 19 },
                }}
            >
                <Form.Item label="Type">
                    <Select
                        value={selectedPaper.id}
                        style={{ width: "100%" }}
                        onChange={handleSelectChange}
                    >
                        {papers.map((paper) => (
                            <Option key={paper.id} value={paper.id}>
                                {paper.type}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Width">
                    <InputNumber
                        min={0}
                        value={selectedPaper.width}
                        onChange={(value) =>
                            handleInputNumberChange("width", value)
                        }
                    />
                </Form.Item>
                <Form.Item label="Height">
                    <InputNumber
                        min={0}
                        value={selectedPaper.height}
                        onChange={(value) =>
                            handleInputNumberChange("height", value)
                        }
                    />
                </Form.Item>
                <Form.Item label="Style">
                    <div
                        style={{
                            height: 50,
                            overflow: "auto",
                        }}
                    >
                        <Editor
                            textareaClassName="htmlCSSEditor"
                            padding={10}
                            value={selectedPaper.style}
                            onValueChange={handleStyleChange}
                            highlight={(code) =>
                                Prism.highlight(
                                    code,
                                    Prism.languages.css,
                                    "css"
                                )
                            }
                            style={{
                                fontFamily:
                                    '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                border: "0px",
                                borderWidth: 0,
                            }}
                        />
                    </div>
                </Form.Item>
                <div style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={handlePaperPropsApply}>
                        Apply
                    </Button>
                </div>
            </Form>
        </Card>
    );
};

export default PaperProperties;
