import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    Typography,
    Divider,
    Button,
    Row,
    Col,
    message,
} from "antd";

import http from "services/httpService";
import { apiUrl } from "config.json";

const ChangeLog = (props) => {
    const [isEdit, setIsEdit] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(false);

    const { Title } = Typography;
    const { TextArea } = Input;

    useEffect(() => {
        const changeLogID = props.match.params.change_log_id || null;

        const fetchData = async () => {
            try {
                setIsContentLoading(true);
                const response = await http.get(
                    `${apiUrl}/changeLog/${changeLogID}`
                );
                const { changeLog } = response.data;
                if (changeLog) {
                    let content = changeLog.content;
                    content = content.replace(/&#x26A1;/g, "⚡");
                    content = content.replace(/&#x1F6E0;&#xFE0F;/g, "🛠️");
                    content = content.replace(/&#x1F41E;/g, "🐞");
                    content = content.replace(/&#x1F3C6;/g, "🏆");
                    content = content.replace(/&#x1F4E2;/g, "📢");

                    form.setFieldsValue({
                        title: changeLog.title,
                        content: content,
                    });
                    setIsEdit(true);
                } else {
                    message.error("No Change Log Found");
                }
                setIsContentLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (changeLogID) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFormFinish = async (formData) => {
        try {
            setIsSaving(true);

            let content = formData.content;

            content = content.replace(/⚡/g, "&#x26A1;");
            content = content.replace(/🛠️/g, "&#x1F6E0;&#xFE0F;");
            content = content.replace(/🐞/g, "&#x1F41E;");
            content = content.replace(/🏆/g, "&#x1F3C6;");
            content = content.replace(/📢/g, "&#x1F4E2;");

            if (isEdit) {
                const changeLogID = props.match.params.change_log_id;
                await http.put(`${apiUrl}/changeLog/${changeLogID}`, {
                    ...formData,
                    hash: formData.title,
                    content: content,
                });
                message.success("Change log saved!");
            } else {
                await http.post(`${apiUrl}/changeLog/`, {
                    ...formData,
                    hash: formData.title,
                    content: content,
                });
                message.success("Change log saved!");
                form.resetFields();
            }

            setIsSaving(false);
        } catch (error) {
            message.error(error.message || "Something went wrong");
        }
    };

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    return isContentLoading ? (
        <div>Loading</div>
    ) : (
        <div>
            <Title level={4}>Create Change Log</Title>
            <div>
                To edit a change log add change log id to url (/change_log_id)
            </div>
            <Divider />
            <Row>
                <Col lg={20}>
                    <Form {...layout} form={form} onFinish={handleFormFinish}>
                        <Form.Item
                            label="Title/Version:"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Title is required!",
                                },
                            ]}
                        >
                            <Input placeholder="2.0.0" />
                        </Form.Item>
                        <Form.Item
                            label="Content"
                            name="content"
                            rules={[
                                {
                                    required: true,
                                    message: "Title is required!",
                                },
                            ]}
                        >
                            <TextArea autoSize={{ minRows: 5 }} />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <div>
                                <span role="img" aria-label="Feature">
                                    ⚡
                                </span>{" "}
                                - Feature
                            </div>
                            <div>
                                <span role="img" aria-label="Enhancement">
                                    🛠️
                                </span>{" "}
                                - Enhancement
                            </div>
                            <div>
                                <span role="img" aria-label="Fix">
                                    🐞
                                </span>{" "}
                                - Fix
                            </div>
                            <div>
                                <span role="img" aria-label="Achievement">
                                    🏆
                                </span>{" "}
                                - Achievement
                            </div>
                            <div>
                                <span role="img" aria-label="Announcement">
                                    📢
                                </span>{" "}
                                - Announcement
                            </div>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isSaving}
                            >
                                Save Change Log
                            </Button>
                        </Form.Item>
                    </Form>
                    <p>
                        Note: Saving changelog will automatically added to
                        notification table and users will see the notification
                        upon webpage reload.
                    </p>
                </Col>
                <Col lg={4}></Col>
            </Row>
        </div>
    );
};

export default ChangeLog;
