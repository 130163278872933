import React, { useState, useEffect } from "react";
import { Empty, Dropdown, Menu, Tooltip, Modal, Input, Button } from "antd";
import {
    PrinterOutlined,
    FileOutlined,
    FileExcelOutlined,
    DashOutlined,
    ExclamationCircleOutlined,
    ThunderboltOutlined,
    MenuOutlined,
    ImportOutlined,
} from "@ant-design/icons";

import Draggable from "react-draggable";
import ReactHtmlParser from "react-html-parser";

import BalloonEditor from "./Components/BalloonEditor/Index";

import { CSSToReactStyle } from "helpers/simpleCSSToReactStyle";

const PaperPreview = ({
    paperData,
    onJSONStringLoad,
    onChange,
    onClear,
    onCreateNew,
}) => {
    const [paperStyle, setPaperStyle] = useState(null);
    const [isRowLineVisible, setIsRowLineVisible] = useState(true);

    const [JSONString, setJSONString] = useState("");
    const [isJSONStringVisible, setIsJSONStringVisible] = useState(false);
    const [isLoadJSONStringVisible, setIsLoadJSONStringVisible] = useState(
        false
    );

    const [isBalloonEditorVisible, setIsBalloonEditorVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const { confirm } = Modal;
    const { TextArea } = Input;

    useEffect(() => {
        if (paperData.props) {
            const paperStyle = {
                ...CSSToReactStyle(paperData.props.style),
                width: paperData.props.width,
                height: paperData.props.height,
            };
            setPaperStyle(paperStyle);
        }
    }, [paperData.props]);

    const toggleJSONStringVisible = () => {
        setIsJSONStringVisible(!isJSONStringVisible);
    };

    const toggleLoadJSONStringVisible = () => {
        setIsLoadJSONStringVisible(!isLoadJSONStringVisible);
    };

    const toggleRowLineVisible = () => {
        setIsRowLineVisible(!isRowLineVisible);
    };

    const toggleBalloonEditorVisible = () => {
        setIsBalloonEditorVisible(!isBalloonEditorVisible);
    };

    const handleLoadJSONString = (JSONString) => {
        toggleLoadJSONStringVisible();
        onJSONStringLoad(JSONString);
    };

    const printPreview = () => {
        const paperProps = paperData.props;
        const rows = paperData.rows;
        let outputHtml = "";
        rows.forEach((row) => {
            outputHtml += `<div style="${row.style}">${row.content}</div>`;
        });
        const { width, height, style } = paperProps;
        outputHtml = `<div style="width: ${width}px; height: ${height}px; ${style}">${outputHtml}</div>`;

        //open new window
        const windowWidth = 860;
        const windowHeight = 580;
        const windowXPos =
            window.top.outerWidth / 2 + window.top.screenX - windowWidth / 2;
        let paperPreviewWindow = window.open(
            "",
            "_blank",
            `width=${windowWidth},height=${windowHeight},top=70,left=${windowXPos},titlebar=no,menubar=no`
        );
        paperPreviewWindow.document.write(
            `<style>
                html * {
                    box-sizing: border-box;                    
                }
                body {
                    margin: 0;
                    line-height: 1.5715;
                    font-size: 14px;
                    font-family: "Times New Roman", Times, serif;                  
                }
                h1, h2, h3, h4, h5, h6, p {
                    margin: 0;
                    padding: 0;
                    border: 0;
                    font-size: 100%;
                    font: inherit;
                    vertical-align: baseline;
                }
                @media print
                {
                    .no-print, .no-print *
                    {
                        display: none !important;
                    }
                }
            </style>
            <div>
                <div class="no-print" style="background-color:#1890ff; padding:10px;  color:#fff; display:flex; flex-direction: row; justify-content: space-between;">
                    <div>
                        Edit the contents below and then click the Print Preview button once done!
                    </div>
                    <div>
                        <button onclick="window.print()">Print Preview</button>
                    </div>
                </div>
                <div contenteditable="true">${outputHtml}</div>
            </div>`
        );
        //paperPreviewWindow.document.write(outputHtml);
    };

    const getData = () => {
        // const newRows = paperData.rows.map((row) => {
        //     let newContent = row.content.replace(/\n/g, "");
        //     newContent = newContent.replace(/>\s+</g, "><");
        //     return {
        //         ...row,
        //         content: newContent,
        //     };
        // });
        // setJSONString(JSON.stringify({ ...paperData, rows: newRows }));
        setJSONString(JSON.stringify(paperData));
        toggleJSONStringVisible();
    };

    const clearContent = () => {
        confirm({
            title:
                "Are you sure do you want to clear the contents of the paper?",
            icon: <ExclamationCircleOutlined />,
            content: "This cannot be undone.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                onClear();
            },
        });
    };

    const newPaper = () => {
        confirm({
            title: "Are you sure do you want create new paper?",
            icon: <ExclamationCircleOutlined />,
            content: "This will reset all your work.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                // localStorage.removeItem("savedData");
                // window.location.reload();
                onCreateNew();
            },
        });
    };

    const showBalloonEditor = (selectedRecord) => {
        setSelectedRow(selectedRecord);
        setIsBalloonEditorVisible(true);
    };

    const handleBalloonEditorChange = (rowId, content, type) => {
        onChange(rowId, content, type);
    };

    const paperPreviewMenu = (
        <Menu>
            <Menu.Item key="1" onClick={printPreview}>
                <PrinterOutlined /> Print
            </Menu.Item>
            <Menu.Item key="2" onClick={getData}>
                <FileOutlined /> Get Data
            </Menu.Item>
            <Menu.Item key="3" onClick={toggleRowLineVisible}>
                <DashOutlined /> {isRowLineVisible ? "Remove" : "Show"} Lines
            </Menu.Item>
            <Menu.Item key="4" onClick={clearContent}>
                <FileExcelOutlined /> Clear Content
            </Menu.Item>
            <Menu.Item key="5" onClick={newPaper}>
                <ThunderboltOutlined /> New Paper
            </Menu.Item>
        </Menu>
    );

    const FormLoadJSONString = ({ onSubmit }) => {
        const [value, setValue] = useState("");

        const handleChange = (e) => {
            const { value } = e.target;
            setValue(value);
        };

        const handleLoad = () => {
            onSubmit(value);
        };

        return (
            <>
                <TextArea
                    autoSize={{ minRows: 10, maxRows: 11 }}
                    value={value}
                    onChange={handleChange}
                />
                <Button
                    type="primary"
                    style={{ marginTop: 10 }}
                    onClick={handleLoad}
                >
                    Load
                </Button>
            </>
        );
    };

    return paperData.props ? (
        <div>
            <Dropdown overlay={paperPreviewMenu} trigger={["contextMenu"]}>
                <div
                    className="cssreset shadowEffect"
                    style={{ ...paperStyle, position: "relative" }}
                >
                    {paperData.rows.map((row) => (
                        <Tooltip key={row.id} title={row.name} placement="left">
                            <div
                                className="paperRowPreview"
                                style={{
                                    ...CSSToReactStyle(row.style),
                                    borderBottom: isRowLineVisible
                                        ? "1px dashed #000"
                                        : "none",
                                    marginBottom: isRowLineVisible ? -1 : 0,
                                }}
                                onClick={() => showBalloonEditor(row)}
                            >
                                {ReactHtmlParser(row.content)}
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </Dropdown>

            {isBalloonEditorVisible && (
                <Draggable handle=".handle">
                    <div
                        className="balloonEditor"
                        style={{
                            position: "absolute",
                            top: 0,
                            backgroundColor: "#fff",
                            zIndex: 5,
                            width: 500,
                        }}
                    >
                        <div
                            className="handle"
                            style={{
                                textAlign: "center",
                                cursor: "move",
                                backgroundColor: "#001529",
                                color: "#878787",
                            }}
                        >
                            <MenuOutlined />
                        </div>
                        <BalloonEditor
                            rows={paperData.rows}
                            selectedRow={selectedRow}
                            onChange={handleBalloonEditorChange}
                            onClose={toggleBalloonEditorVisible}
                        />
                    </div>
                </Draggable>
            )}

            <Modal
                title="JSON Output"
                visible={isJSONStringVisible}
                footer={null}
                onCancel={toggleJSONStringVisible}
            >
                <TextArea
                    autoSize={{ minRows: 10, maxRows: 11 }}
                    value={JSONString}
                />
            </Modal>
        </div>
    ) : (
        <div style={{ marginTop: 50 }}>
            <Empty description="No Paper Selected">
                <Button type="primary" onClick={toggleLoadJSONStringVisible}>
                    <ImportOutlined /> Load JSON String
                </Button>
            </Empty>
            <Modal
                title="Load JSON String"
                visible={isLoadJSONStringVisible}
                footer={null}
                onCancel={toggleLoadJSONStringVisible}
            >
                <FormLoadJSONString onSubmit={handleLoadJSONString} />
            </Modal>
        </div>
    );
};

export default PaperPreview;
