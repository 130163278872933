import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableItem = sortableElement((props) => (
    <tr className="ant-table-row" {...props} />
));
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "move", color: "#999" }} />
));

const FormMoveCols = ({ formRow, onSortOk }) => {
    const [formRowCols, setFormRowCols] = useState([]);

    useEffect(() => {
        setFormRowCols(formRow.cols);
    }, [formRow]);

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        //const { dataSource } = this.state;
        if (oldIndex !== newIndex) {
            const newCols = arrayMove(
                [].concat(formRowCols),
                oldIndex,
                newIndex
            ).filter((el) => !!el);
            setFormRowCols(newCols);
        }
    };

    const sortOk = () => {
        onSortOk(formRowCols);
    };

    const DraggableContainer = (props) => (
        <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            lockAxis="y"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = formRowCols.findIndex(
            (x) => x.id === restProps["data-row-key"]
        );
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Name",
            dataIndex: "name",
            className: "drag-visible cursorPointer",
        },
    ];

    return (
        <>
            <p>Use handle to drag and arrage columns.</p>
            <Table
                pagination={false}
                dataSource={formRowCols}
                columns={columns}
                rowKey="id"
                showHeader={false}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
            />
            <Button type="primary" onClick={sortOk} style={{ marginTop: 16 }}>
                OK
            </Button>
        </>
    );
};

export default FormMoveCols;
