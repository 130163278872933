import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Avatar } from "antd";
import {
    PieChartOutlined,
    UserOutlined,
    MessageOutlined,
    CreditCardOutlined,
    BellOutlined,
    EditOutlined,
    BulbOutlined,
    FormOutlined,
} from "@ant-design/icons";

const Sidebar = ({ activeMenu, breakpoint }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const { Sider } = Layout;

    const handleCollapse = (collapsed) => {
        setIsCollapsed(collapsed);
    };

    return (
        <Sider
            //theme="light"
            collapsible
            collapsed={isCollapsed}
            onCollapse={handleCollapse}
        >
            <div
                style={{
                    margin: 16,
                    textAlign: "center",
                }}
            >
                <Avatar
                    style={{ backgroundColor: "#1890ff" }}
                    icon={<UserOutlined />}
                />
                {!isCollapsed && (
                    <>
                        &nbsp;&nbsp;&nbsp;
                        <span style={{ color: "#fff" }}>CWA Admin</span>
                    </>
                )}
            </div>
            <Menu
                theme="dark"
                defaultSelectedKeys={[activeMenu]}
                selectedKeys={[activeMenu]}
                mode="inline"
            >
                <Menu.Item key="Dashboard" icon={<PieChartOutlined />}>
                    <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>
                <Menu.Item key="Messages" icon={<MessageOutlined />}>
                    <Link to="/messages">Messages</Link>
                </Menu.Item>
                <Menu.Item key="Payments" icon={<CreditCardOutlined />}>
                    <Link to="/payments">Payments</Link>
                </Menu.Item>
                <Menu.Item key="Notifications" icon={<BellOutlined />}>
                    <Link to="/notifications">Notifications</Link>
                </Menu.Item>
                <Menu.Item key="Change Log" icon={<EditOutlined />}>
                    <Link to="/changeLog">Change Log</Link>
                </Menu.Item>
                <Menu.Item key="Paper Builder" icon={<BulbOutlined />}>
                    <Link to="/paperBuilder">Paper Builder</Link>
                </Menu.Item>
                <Menu.Item key="Form Builder" icon={<FormOutlined />}>
                    <Link to="/formBuilder">Form Builder</Link>
                </Menu.Item>
                <Menu.Item key="Test" icon={<FormOutlined />}>
                    <Link to="/test">Test</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};

export default Sidebar;
