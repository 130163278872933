import React, { useState } from "react";
import {
    DatePicker,
    Card,
    Typography,
    Row,
    Col,
    Tag,
    Input,
    Avatar,
    Alert,
    Select,
    Empty,
    message,
} from "antd";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    LoadingOutlined,
    UserOutlined,
} from "@ant-design/icons";
import moment from "moment";

import http from "services/httpService";
import { apiUrl } from "config.json";

const PatientPortalPayments = () => {
    const [filterType, setFilterType] = useState("search");
    const [payments, setPayments] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [dateString, setDateString] = useState("");

    const { RangePicker } = DatePicker;
    const { Title } = Typography;
    const { Search } = Input;
    const { Option } = Select;

    const handleFilterTypeChange = (value) => {
        setFilterType(value);
        setPayments([]);
        setSearchString("");
    };

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
    };

    const handleDateRangeChange = async (date, dateString) => {
        const [dateStart, dateEnd] = dateString;
        setDateString(dateString);
        if (dateStart !== "" && dateEnd !== "") {
            try {
                setSearchString("");
                setIsSearching(true);
                const response = await http.get(
                    `${apiUrl}/payment/search/${dateStart}/${dateEnd}`,
                    {
                        headers: {
                            conn: "pp",
                        },
                    }
                );
                setPayments(response.data.payments);
                setIsSearching(false);
            } catch (error) {
                message.error(error.message || "Something went wrong! :(");
                setIsSearching(false);
            }
        } else {
            setPayments([]);
        }
    };

    const handlePaymentSearch = async (value) => {
        if (filterType === "daterange") {
            if (value.trim() !== "") {
                let code = value.match(/(.*?:)/);
                if (code) {
                    code = code[0].trim();
                    const searchString = value.replace(code, "").trim();
                    let newPayments = [...payments];
                    switch (code) {
                        case "p:":
                            newPayments = payments.filter((payment) => {
                                const {
                                    patient_firstname,
                                    patient_middlename,
                                    patient_lastname,
                                } = payment;
                                let patientName = `${patient_firstname} ${patient_middlename} ${patient_lastname}`;
                                patientName = patientName.toLowerCase();
                                return patientName.includes(
                                    searchString.toLowerCase()
                                );
                            });
                            break;
                        case "d:":
                            newPayments = payments.filter((payment) => {
                                const {
                                    doctor_firstname,
                                    doctor_middlename,
                                    doctor_lastname,
                                } = payment;
                                let doctorName = `${doctor_firstname} ${doctor_middlename} ${doctor_lastname}`;
                                doctorName = doctorName.toLowerCase();
                                return doctorName.includes(
                                    searchString.toLowerCase()
                                );
                            });
                            break;
                        case "as:":
                            newPayments = payments.filter(
                                (payment) =>
                                    payment.appointment_status === searchString
                            );
                            break;
                        case "ps:":
                            newPayments = payments.filter(
                                (payment) => payment.status === searchString
                            );
                            break;
                        case "aid:":
                            newPayments = payments.filter(
                                (payment) =>
                                    payment.appointment_id === searchString
                            );
                            break;
                        case "pid:":
                            newPayments = payments.filter(
                                (payment) => payment.payment_id === searchString
                            );
                            break;
                        default:
                            newPayments = [];
                            message.error("search code not found");
                    }
                    setPayments(newPayments);
                } else {
                    setPayments(payments);
                }
            } else {
                handleDateRangeChange("", dateString);
            }
        } else {
            try {
                setIsSearching(true);
                const response = await http.get(`${apiUrl}/search/payments`, {
                    headers: {
                        conn: "pp",
                    },
                });

                const { payments } = response.data;

                if (value.trim() !== "") {
                    let code = value.match(/(.*?:)/);
                    if (code) {
                        code = code[0].trim();
                        const searchString = value.replace(code, "").trim();
                        let newPayments = [];
                        switch (code) {
                            case "p:":
                                newPayments = payments.filter((payment) => {
                                    const {
                                        patient_firstname,
                                        patient_middlename,
                                        patient_lastname,
                                    } = payment;
                                    let patientName = `${patient_firstname} ${patient_middlename} ${patient_lastname}`;
                                    patientName = patientName.toLowerCase();
                                    return patientName.includes(
                                        searchString.toLowerCase()
                                    );
                                });
                                break;
                            case "d:":
                                newPayments = payments.filter((payment) => {
                                    const {
                                        doctor_firstname,
                                        doctor_middlename,
                                        doctor_lastname,
                                    } = payment;
                                    let doctorName = `${doctor_firstname} ${doctor_middlename} ${doctor_lastname}`;
                                    doctorName = doctorName.toLowerCase();
                                    return doctorName.includes(
                                        searchString.toLowerCase()
                                    );
                                });
                                break;
                            case "as:":
                                newPayments = payments.filter(
                                    (payment) =>
                                        payment.appointment_status ===
                                        searchString
                                );
                                break;
                            case "ps:":
                                newPayments = payments.filter(
                                    (payment) => payment.status === searchString
                                );
                                break;
                            case "aid:":
                                newPayments = payments.filter(
                                    (payment) =>
                                        payment.appointment_id === searchString
                                );
                                break;
                            case "pid:":
                                newPayments = payments.filter(
                                    (payment) =>
                                        payment.payment_id === searchString
                                );
                                break;
                            default:
                                newPayments = [];
                                message.error("search code not found");
                        }
                        setPayments(newPayments);
                    } else {
                        setPayments([]);
                    }
                } else {
                    setPayments(payments);
                }

                setIsSearching(false);
            } catch (error) {
                message.error(error.message || "Something went wrong! :(");
                setIsSearching(false);
            }
        }
    };

    const PaymentList = ({ payments }) => {
        let totalCount = payments.length;
        let totalPayment = 0;
        let totalDoctorFee = 0;

        payments.forEach((payment) => {
            totalPayment += payment.appointment_amount;
            totalDoctorFee += payment.appointment_consultation_price;
        });

        return (
            <>
                <Alert
                    type="info"
                    message={
                        <div>
                            Total Count: {totalCount}
                            &nbsp;&nbsp;&nbsp;&nbsp;Total Payment Amount: PHP{" "}
                            {totalPayment}&nbsp;&nbsp;&nbsp;&nbsp;Total Doctor
                            Fee: PHP {totalDoctorFee}
                        </div>
                    }
                    style={{ marginBottom: 16 }}
                    showIcon
                />

                {payments.map((payment) => {
                    const {
                        payment_id,
                        status,
                        status_note,
                        payment_type,
                        payment_intent,
                        payment_resource,
                        created_at,

                        patient_firstname,
                        patient_lastname,
                        patient_middlename,

                        appointment_date,
                        appointment_duration,
                        appointment_id,
                        appointment_start,
                        appointment_status,
                        //appointment_consultation_final_price,
                        appointment_consultation_price,
                        appointment_items,
                        appointment_amount,
                        appointment_app_misc_percent,

                        doctor_firstname,
                        doctor_lastname,
                        doctor_middlename,
                        doctor_degree,
                        //doctor_img_path,
                        doctor_aop_name,
                        doctor_consultation_desc,
                        doctor_consultation_price,
                    } = payment;

                    const patientName = `${patient_lastname}, ${patient_firstname} ${patient_middlename}`;
                    const doctorName = `${doctor_firstname} ${doctor_lastname} ${doctor_middlename} ${doctor_degree}`;

                    let paymentType = payment_type;
                    if (payment_type === "card") {
                        paymentType = "Credit/Debit Card";
                    } else if (payment_type === "gcash") {
                        paymentType = "GCash";
                    } else if (payment_type === "grabpay") {
                        paymentType = "GrapPay";
                    }

                    let paymentStatusColor = "orange";
                    if (status === "succeeded") {
                        paymentStatusColor = "green";
                    }

                    let appointmentStatusColor = "red";
                    if (status === "pending") {
                        paymentStatusColor = "orange";
                    } else if (status === "scheduled") {
                        paymentStatusColor = "green";
                    } else if (status === "expired") {
                        paymentStatusColor = "red";
                    } else if (status === "done") {
                        paymentStatusColor = "blue";
                    }

                    return (
                        <div
                            key={payment.payment_id}
                            style={{ marginBottom: 16 }}
                        >
                            <Card
                                title={
                                    <Row type="flex" justify="space-between">
                                        <Col>
                                            Payment Date:{" "}
                                            {moment(created_at).format("LL")}
                                        </Col>
                                        <Col>PHP {appointment_amount}</Col>
                                    </Row>
                                }
                            >
                                <Row gutter={16}>
                                    <Col lg={8}>
                                        <div style={{ textAlign: "center" }}>
                                            <Avatar
                                                icon={<UserOutlined />}
                                                style={{
                                                    backgroundColor: "#f50",
                                                }}
                                            />
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            <Title level={4}>
                                                {patientName}
                                            </Title>
                                        </div>
                                        <div>
                                            Appointment Id: {appointment_id}
                                        </div>
                                        <div>
                                            Appointment Date:{" "}
                                            {moment(appointment_date).format(
                                                "LL"
                                            )}
                                        </div>
                                        <div>
                                            Appointment Start:{" "}
                                            {moment(
                                                appointment_start,
                                                "HH:mm:ss"
                                            ).format("hh:mm A")}{" "}
                                            ({appointment_duration} mins)
                                        </div>

                                        <div>
                                            Appointment Status:{" "}
                                            <Tag color={appointmentStatusColor}>
                                                {appointment_status}
                                            </Tag>
                                        </div>
                                        <div>
                                            Payment: PHP {appointment_amount}
                                        </div>
                                        <div>Items: {appointment_items}</div>
                                    </Col>
                                    <Col lg={10}>
                                        <div style={{ textAlign: "center" }}>
                                            <Avatar
                                                icon={<UserOutlined />}
                                                style={{
                                                    backgroundColor: "#108ee9",
                                                }}
                                            />
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            <Title level={4}>
                                                {doctorName}
                                            </Title>
                                        </div>
                                        <div>{doctor_aop_name}</div>
                                        <div>
                                            {doctor_consultation_desc}: PHP{" "}
                                            {appointment_consultation_price}{" "}
                                        </div>
                                        <div>
                                            Appointment{" "}
                                            {doctor_consultation_desc}: PHP{" "}
                                            {appointment_consultation_price +
                                                (appointment_consultation_price *
                                                    appointment_app_misc_percent) /
                                                    100}{" "}
                                        </div>
                                        <div>
                                            Current {doctor_consultation_desc}:
                                            PHP {doctor_consultation_price}{" "}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <Alert
                                            type="info"
                                            message="Payment Info"
                                            description={
                                                <div>
                                                    <div>
                                                        Payment Id: {payment_id}
                                                    </div>
                                                    <div>
                                                        Type:{" "}
                                                        {paymentType ||
                                                            "pending"}
                                                    </div>
                                                    <div>
                                                        Status:{" "}
                                                        <Tag
                                                            color={
                                                                paymentStatusColor
                                                            }
                                                        >
                                                            {status}
                                                        </Tag>
                                                    </div>
                                                    <div>
                                                        Status Note:{" "}
                                                        {status_note}
                                                    </div>
                                                    {paymentType && (
                                                        <div>
                                                            {payment_type ===
                                                            "card" ? (
                                                                <div>
                                                                    Payment
                                                                    Intents:{" "}
                                                                    {payment_intent ? (
                                                                        <CheckCircleFilled
                                                                            style={{
                                                                                color:
                                                                                    "#4caf50",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CloseCircleFilled
                                                                            style={{
                                                                                color:
                                                                                    "#f44336",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    Payment
                                                                    Resource:{" "}
                                                                    {payment_resource ? (
                                                                        <CheckCircleFilled
                                                                            style={{
                                                                                color:
                                                                                    "#4caf50",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CloseCircleFilled
                                                                            style={{
                                                                                color:
                                                                                    "#f44336",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <div style={{ marginBottom: 16 }}>
                Filter By:{" "}
                <Select
                    style={{ width: 300 }}
                    value={filterType}
                    onChange={handleFilterTypeChange}
                >
                    <Option value="daterange">Date Range with Search</Option>
                    <Option value="search">Search Only</Option>
                </Select>
            </div>
            {filterType === "search" ? (
                <div style={{ marginBottom: 16 }}>
                    <Input.Group>
                        <Search
                            value={searchString}
                            placeholder="p: d: as: ps: aid: pid:"
                            enterButton
                            onSearch={handlePaymentSearch}
                            onChange={handleSearchChange}
                        />
                    </Input.Group>
                </div>
            ) : (
                <>
                    <div
                        type="flex"
                        justify="space-between"
                        style={{ marginBottom: 16 }}
                    >
                        Select Date Range:{" "}
                        <RangePicker onChange={handleDateRangeChange} />
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <Input.Group>
                            <Search
                                value={searchString}
                                placeholder="p: d: as: ps: aid: pid:"
                                enterButton
                                onSearch={handlePaymentSearch}
                                onChange={handleSearchChange}
                            />
                        </Input.Group>
                    </div>
                </>
            )}
            {isSearching ? (
                <div style={{ paddingTop: 50, textAlign: "center" }}>
                    <LoadingOutlined style={{ fontSize: 40 }} />
                </div>
            ) : payments.length === 0 ? (
                <div style={{ paddingTop: 50 }}>
                    <Empty />
                </div>
            ) : (
                <PaymentList payments={payments} />
            )}
        </>
    );
};

export default PatientPortalPayments;
