import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRequestError } from "redux/system/system.actions";
import { Statistic, Typography, Row, Col, Card, Divider } from "antd";
import moment from "moment";

import ContentLoader from "globalComponents/ContentLoader";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Dashboard = ({ setRequestError }) => {
    const [isContentLoading, setIsContentLoading] = useState(true);

    const [CWAStats, setCWAStats] = useState({
        accounts: 0,
        clinics: 0,
        doctor: 0,
        staff: 0,
    });

    const [patientPortal, setPatientPortal] = useState({
        patients: 0,
        completed: 0,
        incomplete: 0,
        recentPatients: 0,
        forVideoCallToday: 0,
        scheduledAppointment: 0,
        callDuration: 0,
        availableDoctors: 0,
    });

    const [patientPortalPayments, setPatientPortalPayments] = useState({
        total: 0,
        success: 0,
        pending: 0,
        failed: 0,
    });

    const { Title } = Typography;

    useEffect(() => {
        const fetchData = async () => {
            try {
                //const currentMonth = ;
                const yearMonth = moment().format("YYYY-MM");
                const dateToday = moment().format("YYYY-MM-DD");
                const response = await http.get(
                    `${apiUrl}/adminDashboardData/${yearMonth}/${dateToday}`,
                    {
                        headers: {
                            conn: "pp",
                        },
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );

                const {
                    accounts,
                    clinics,
                    doctors,
                    staffs,
                    patientsOnPortal,
                    completedPatientDataOnPortal,
                    incompletePatientDataOnPortal,
                    recentPatients,
                    videCallToday,
                    scheduledAppointment,
                    videoCallsDuration,
                    availableDoctors,
                    totalPayments,
                    successPayments,
                    pendingPayments,
                    failedPayments,
                } = response.data;

                const durations = videoCallsDuration.map((duration) => {
                    return duration.video_duration;
                });
                const totalDurations = durations
                    .slice(1)
                    .reduce(
                        (prev, cur) => moment.duration(cur).add(prev),
                        moment.duration(durations[0])
                    );

                setCWAStats({
                    accounts: accounts.length,
                    clinics: clinics[0].clinics,
                    doctor: doctors[0].doctors,
                    staff: staffs[0].staffs,
                });
                setPatientPortal({
                    patients: patientsOnPortal[0].patients,
                    completed: completedPatientDataOnPortal[0].patients,
                    incomplete: incompletePatientDataOnPortal[0].patients,
                    recentPatients: recentPatients[0].recentPatients,
                    forVideoCallToday: videCallToday[0].videCallToday,
                    scheduledAppointment:
                        scheduledAppointment[0].scheduledAppointment,
                    callDuration: moment
                        .utc(totalDurations.asMilliseconds())
                        .format("HH:mm:ss"),
                    availableDoctors: availableDoctors[0].availableDoctors,
                });
                setPatientPortalPayments({
                    total: totalPayments[0].totalPayments,
                    success: successPayments[0].successPayments,
                    pending: pendingPayments[0].pendingPayments,
                    failed: failedPayments[0].failedPayments,
                });
                setIsContentLoading(false);
            } catch (error) {
                setRequestError({
                    errorMessage: "Something went wrong :(",
                    errorSubMessage: error.message,
                });
            }
        };

        fetchData();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isContentLoading ? (
        <ContentLoader />
    ) : (
        <>
            <Title level={4}>Clinic Web Assistant</Title>
            <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Active Accounts"
                            value={CWAStats.accounts}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Clinics" value={CWAStats.clinics} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Doctor" value={CWAStats.doctor} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title="Staff" value={CWAStats.staff} />
                    </Card>
                </Col>
            </Row>
            <Divider />
            <Title level={4}>Patient Portal</Title>
            <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Patients"
                            value={patientPortal.patients}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Completed"
                            value={patientPortal.completed}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Incomplete"
                            value={patientPortal.incomplete}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title={`Recent Patients (${moment().format(
                                "MMMM"
                            )})`}
                            value={patientPortal.recentPatients}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="For Video Call Today"
                            value={patientPortal.forVideoCallToday}
                            valueStyle={{ color: "#3f8600" }}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Scheduled Appointments"
                            value={patientPortal.scheduledAppointment}
                            valueStyle={{ color: "#3f8600" }}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title={`Call Duration (${moment().format("MMMM")})`}
                            value={patientPortal.callDuration}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Available Doctors"
                            value={patientPortal.availableDoctors}
                        />
                    </Card>
                </Col>
            </Row>
            <Divider />
            <Title level={4}>Patient Payments</Title>
            <Row gutter={16}>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Total Payments"
                            value={patientPortalPayments.total}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Success Payments"
                            value={patientPortalPayments.success}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Pending Payments"
                            value={patientPortalPayments.pending}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Failed Payments"
                            value={patientPortalPayments.failed}
                            valueStyle={{ color: "#cf1322" }}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setRequestError: (data) => dispatch(setRequestError(data)),
});

export default connect(null, mapDispatchToProps)(Dashboard);
