import React from "react";
import { Button, Form, Row, Col } from "antd";

import FormItemRender from "./Components/FormItemRender";

import { CSSToReactStyle } from "helpers/simpleCSSToReactStyle";
import { titleCase, upperCase, lowerCase } from "helpers/textTransformer";

const FormRender = ({
    formInstance,
    properties: formProperties,
    rows: formRows,
    onFinish,
}) => {
    const [form] = Form.useForm(null);

    const formItemsMapped = formRows.reduce((acc, item) => {
        item.cols.forEach((formRowCol) => {
            formRowCol.formItems.forEach((formItem) => {
                acc[formItem.name] = formItem;
            });
        });
        return acc;
    }, {});

    const handleFormValuesChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];
        const value = changedValues[Object.keys(changedValues)[0]];
        const transform = formItemsMapped[fieldName].transform;
        if (transform !== "normal") {
            switch (transform) {
                case "titleCase":
                    if (formInstance) {
                        formInstance.setFieldsValue({
                            [fieldName]: titleCase(value),
                        });
                    } else {
                        form.setFieldsValue({
                            [fieldName]: titleCase(value),
                        });
                    }
                    break;
                case "upperCase":
                    if (formInstance) {
                        formInstance.setFieldsValue({
                            [fieldName]: upperCase(value),
                        });
                    } else {
                        form.setFieldsValue({
                            [fieldName]: upperCase(value),
                        });
                    }
                    break;
                case "lowerCase":
                    if (formInstance) {
                        formInstance.setFieldsValue({
                            [fieldName]: lowerCase(value),
                        });
                    } else {
                        form.setFieldsValue({
                            [fieldName]: lowerCase(value),
                        });
                    }
                    break;
                default:
                    return false;
            }
        }
    };

    const handleFormFinish = (values) => {
        if (onFinish) {
            onFinish(values);
        }
    };

    return (
        <Form
            {...formProperties.layout}
            form={formInstance || form}
            onValuesChange={handleFormValuesChange}
            onFinish={handleFormFinish}
        >
            {formRows.map((formRow) => (
                <div key={formRow.id} style={CSSToReactStyle(formRow.style)}>
                    <Row>
                        {formRow.cols.map((formRowCol) => (
                            <Col
                                key={formRowCol.id}
                                xl={formRowCol.width.xl}
                                lg={formRowCol.width.lg}
                                md={formRowCol.width.md}
                                sm={formRowCol.width.sm}
                                xs={formRowCol.width.xs}
                            >
                                <div style={CSSToReactStyle(formRowCol.style)}>
                                    {formRowCol.formItems.length === 0 ? (
                                        <span>&nbsp;</span>
                                    ) : (
                                        <FormItemRender
                                            formItems={formRowCol.formItems}
                                        />
                                    )}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
            {formProperties.submitButton.show && (
                <div
                    style={
                        formProperties.submitButton.containerStyle
                            ? {
                                  ...CSSToReactStyle(
                                      formProperties.submitButton.containerStyle
                                  ),
                                  width: "100%",
                              }
                            : null
                    }
                >
                    <Form.Item noStyle={true}>
                        <Button
                            type="primary"
                            size={formProperties.submitButton.size}
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            )}
        </Form>
    );
};

export default FormRender;
