import React, { useState, useEffect } from "react";
import { Card, Button, Tabs } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Editor from "./Components/Editor";
import DataList from "./Components/DataList";

const BalloonEditor = ({ selectedRow: sR, rows, onChange, onClose }) => {
    const [selectedRow, setSelectedRow] = useState(sR);

    useEffect(() => {
        const row = rows.find((row) => row.id === sR.id);
        if (row) {
            setSelectedRow(row);
        } else {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, sR]);

    const handleEditorChange = (rowId, content, type) => {
        setSelectedRow({
            ...selectedRow,
            [type]: content,
        });
        onChange(rowId, content, type);
    };

    const { TabPane } = Tabs;

    return (
        <div>
            <Card
                title={selectedRow.name}
                style={{ width: "100%" }}
                bodyStyle={{ backgroundColor: "#001529", padding: 0 }}
                headStyle={{ backgroundColor: "#1271c9", color: "#fff" }}
                extra={
                    <Button
                        type="primary"
                        size="small"
                        danger
                        onClick={onClose}
                    >
                        <CloseOutlined />
                    </Button>
                }
            >
                <div
                    style={{
                        backgroundColor: "#fff",
                        padding: "12px 12px 20px",
                    }}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Content" key="1">
                            <Editor
                                content={selectedRow.content}
                                lang="html"
                                onChange={(content) =>
                                    handleEditorChange(
                                        selectedRow.id,
                                        content,
                                        "content"
                                    )
                                }
                            />
                        </TabPane>
                        <TabPane tab="Style" key="2">
                            <Editor
                                content={selectedRow.style}
                                lang="css"
                                onChange={(style) =>
                                    handleEditorChange(
                                        selectedRow.id,
                                        style,
                                        "style"
                                    )
                                }
                            />
                        </TabPane>
                        <TabPane tab="Data List" key="3">
                            <DataList />
                        </TabPane>
                    </Tabs>
                </div>
            </Card>
            {/* <div
                style={{
                    textAlign: "center",
                    padding: 10,
                    backgroundColor: "#001529",
                }}
            >
                <Button type="primary" danger onClick={onClose}>
                    Close
                </Button>
            </div> */}
        </div>
    );
};

export default BalloonEditor;
