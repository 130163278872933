import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
//import { systemStoragePath } from "config.json";

const ConversationMessagesHeader = (props) => {
    const { conversationData } = props;
    const {
        name,
        //    img_path
    } = conversationData;

    // const avatarProps = img_path
    //     ? {
    //           size: 40,
    //           src: `${systemStoragePath}/pictures/${img_path}`
    //       }
    //     : { size: 40, icon: "user" };
    const avatarProps = { size: 40, icon: <UserOutlined /> };

    return (
        <div>
            <div
                style={{
                    float: "left",
                    marginRight: 10,
                }}
            >
                <Avatar {...avatarProps} />
            </div>
            <div
                style={{
                    float: "left",
                    marginBottom: 10,
                }}
            >
                <div>
                    <strong>{name}</strong>
                    <br />
                    Active
                </div>
            </div>
        </div>
    );
};

export default ConversationMessagesHeader;
