import React, { useState, useEffect } from "react";
import { Row, Col, Layout } from "antd";
import PaperPreview from "./Components/PaperPreview/Index";
import PaperProperties from "./Components/PaperProperties";
import PaperLayouts from "./Components/PaperLayouts/Index";

import { saveData } from "helpers/autoSave";

function PaperBuilder() {
    const [paperData, setPaperData] = useState({
        props: null,
        rows: [],
    });

    const { Content } = Layout;

    useEffect(() => {
        if (localStorage.getItem("paperBuilder")) {
            setPaperData(JSON.parse(localStorage.getItem("paperBuilder")));
        }
    }, []);

    const handlePaperPropsChange = (paperProps) => {
        const newPaperData = {
            ...paperData,
            props: paperProps,
        };
        setPaperData(newPaperData);
        saveData("paperBuilder", newPaperData);
    };

    const handlePaperLayoutChange = (paperRows) => {
        const newPaperData = {
            ...paperData,
            rows: paperRows,
        };
        setPaperData(newPaperData);
        saveData("paperBuilder", newPaperData);
    };

    const handleJSONStringLoad = (JSONString) => {
        const parseJSONString = JSON.parse(JSONString);
        setPaperData(parseJSONString);
        saveData("paperBuilder", parseJSONString);
    };

    const handlePaperContentChange = (rowId, content, type) => {
        const newRows = paperData.rows.map((row) => {
            if (row.id === rowId) {
                return {
                    ...row,
                    [type]: content,
                };
            }
            return row;
        });

        const newPaperData = {
            ...paperData,
            rows: newRows,
        };
        setPaperData(newPaperData);
        saveData("paperBuilder", newPaperData);
    };

    const handlePaperContentClear = () => {
        const newPaperData = {
            ...paperData,
            rows: [],
        };
        setPaperData(newPaperData);
        saveData("paperBuilder", newPaperData);
    };

    const handlePaperCreateNew = () => {
        const newPaperData = {
            props: null,
            rows: [],
        };
        setPaperData(newPaperData);
        saveData("paperBuilder", newPaperData);
    };

    return (
        <Row gutter={16}>
            <Col span={16}>
                <Layout>
                    <Content
                        style={{
                            height: "100%",
                            overflow: "auto",
                            padding: 0,
                        }}
                    >
                        <PaperPreview
                            paperData={paperData}
                            onJSONStringLoad={handleJSONStringLoad}
                            onChange={handlePaperContentChange}
                            onClear={handlePaperContentClear}
                            onCreateNew={handlePaperCreateNew}
                        />
                    </Content>
                </Layout>
            </Col>
            <Col span={8}>
                <div
                    style={{
                        padding: 0,
                    }}
                >
                    <PaperProperties
                        paperProps={paperData.props}
                        onChange={handlePaperPropsChange}
                    />
                    <PaperLayouts
                        paperData={paperData}
                        onChange={handlePaperLayoutChange}
                    />

                    {/* <Card
                        title="Paper Blocks"
                        extra={
                            <Button size="small" type="primary">
                                Add Block
                            </Button>
                        }
                    >
                        <Empty description="No Paper Block" />
                    </Card>  */}
                </div>
            </Col>
        </Row>
    );
}

export default PaperBuilder;
