import React, { useState, useEffect } from "react";
import { Card, Button, Tooltip, Space, Modal, Table } from "antd";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    MenuOutlined,
} from "@ant-design/icons";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import FormEditRowName from "./Components/FormEditRowName";

import { generateString } from "helpers/randomStringGenerator";

const SortableItem = sortableElement((props) => (
    <tr className="ant-table-row" {...props} />
));
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "move", color: "#999" }} />
));

const PaperLayouts = ({ paperData, onChange }) => {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isFormEditRowNameVisible, setIsFormEditRowNameVisible] = useState(
        false
    );

    const { confirm } = Modal;

    useEffect(() => {
        setRows(paperData.rows);
    }, [paperData.rows]);

    const toggleFormEditRowNameVisible = () => {
        setIsFormEditRowNameVisible(!isFormEditRowNameVisible);
    };

    const showEditRowNameForm = (selectedRecord) => {
        setSelectedRow(selectedRecord);
        toggleFormEditRowNameVisible();
    };

    const handleFormEditRowNameSubmit = (formData) => {
        const rowId = formData.id;
        const rowName = formData.name;
        const newRows = rows.map((row) => {
            if (row.id === rowId) {
                return {
                    ...row,
                    name: rowName,
                };
            }
            return row;
        });
        toggleFormEditRowNameVisible();
        setRows(newRows);
        onChange(newRows);
    };

    const addRow = () => {
        const totalRow = rows.length;
        const newRows = [
            ...rows,
            {
                id: generateString(4, "r"),
                name: `row ${totalRow + 1}`,
                content: `<div>row ${totalRow + 1}</div>`,
                style: "color: #000;",
            },
        ];
        setRows(newRows);
        onChange(newRows);
    };

    const deleteRow = (rowId) => {
        confirm({
            title: "Are you sure do your want to delete this row?",
            icon: <ExclamationCircleOutlined />,
            content: "This cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                const newRows = rows.filter((row) => row.id !== rowId);
                setRows(newRows);
                onChange(newRows);
            },
        });
    };

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        //const { dataSource } = this.state;
        if (oldIndex !== newIndex) {
            const newData = arrayMove(
                [].concat(rows),
                oldIndex,
                newIndex
            ).filter((el) => !!el);
            setRows(newData);
            onChange(newData);
        }
    };

    const DraggableContainer = (props) => (
        <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            lockAxis="y"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const index = rows.findIndex((x) => x.id === restProps["data-row-key"]);
        return <SortableItem index={index} {...restProps} />;
    };

    const columns = [
        {
            title: "Sort",
            dataIndex: "sort",
            width: 30,
            className: "drag-visible",
            render: () => <DragHandle />,
        },
        {
            title: "Name",
            dataIndex: "name",
            className: "drag-visible cursorPointer",
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 30,
            render: (text, record) => {
                return (
                    <Space>
                        {/* <Tooltip title="rename row" placement="left">
                            <EditOutlined
                                onClick={(event) => {
                                    // If you don't want click extra trigger collapse, you can prevent this:
                                    event.stopPropagation();
                                    //showEditRowNameForm(row);
                                }}
                            />
                        </Tooltip> */}
                        <Tooltip title="delete row" placement="left">
                            <DeleteOutlined
                                onClick={(event) => {
                                    // If you don't want click extra trigger collapse, you can prevent this:
                                    event.stopPropagation();
                                    deleteRow(record.id);
                                }}
                            />
                        </Tooltip>
                    </Space>
                );
            },
        },
    ];

    return (
        <Card
            title="Layout"
            extra={
                paperData.props !== null && (
                    <Button size="small" type="primary" onClick={addRow}>
                        Add Row
                    </Button>
                )
            }
            bodyStyle={{ padding: "1px 0px 0px" }}
        >
            <Table
                pagination={false}
                dataSource={rows}
                columns={columns}
                rowKey="id"
                showHeader={false}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: (event) => {
                            showEditRowNameForm(record);
                        }, // double click row
                    };
                }}
            />
            <Modal
                title="Edit Row Name"
                visible={isFormEditRowNameVisible}
                footer={null}
                onCancel={toggleFormEditRowNameVisible}
            >
                <FormEditRowName
                    data={selectedRow}
                    onSubmit={handleFormEditRowNameSubmit}
                />
            </Modal>
        </Card>
    );

    // const handleRowContentChange = (rowId, content) => {
    //     const newRows = rows.map((row) => {
    //         if (row.id === rowId) {
    //             return {
    //                 ...row,
    //                 content,
    //             };
    //         }
    //         return row;
    //     });
    //     setRows(newRows);
    //     onChange(newRows);
    // };

    // const handleRowStyleChange = (rowId, style) => {
    //     const newRows = rows.map((row) => {
    //         if (row.id === rowId) {
    //             return {
    //                 ...row,
    //                 style,
    //             };
    //         }
    //         return row;
    //     });
    //     setRows(newRows);
    //     onChange(newRows);
    // };

    // return (
    //     <Card
    //         title="Layout"
    //         extra={
    //             paperData.props !== null && (
    //                 <Button size="small" type="primary" onClick={addRow}>
    //                     Add Row
    //                 </Button>
    //             )
    //         }
    //         bodyStyle={{ padding: 0 }}
    //     >
    //         {rows.length !== 0 && (
    //             <Collapse accordion>
    //                 {rows.map((row) => (
    //                     <Panel
    //                         header={row.name}
    //                         key={row.id}
    //                         extra={
    //                             <Space>
    //                                 <Tooltip
    //                                     title="rename row"
    //                                     placement="left"
    //                                 >
    //                                     <EditOutlined
    //                                         onClick={(event) => {
    //                                             // If you don't want click extra trigger collapse, you can prevent this:
    //                                             event.stopPropagation();
    //                                             showEditRowNameForm(row);
    //                                         }}
    //                                     />
    //                                 </Tooltip>
    //                                 <Tooltip
    //                                     title="delete row"
    //                                     placement="left"
    //                                 >
    //                                     <DeleteOutlined
    //                                         onClick={(event) => {
    //                                             // If you don't want click extra trigger collapse, you can prevent this:
    //                                             event.stopPropagation();
    //                                             deleteRow(row.id);
    //                                         }}
    //                                     />
    //                                 </Tooltip>
    //                             </Space>
    //                         }
    //                     >
    //                         <Tabs>
    //                             <TabPane tab="Content" key="1">
    //                                 <Editor
    //                                     content={row.content}
    //                                     lang="html"
    //                                     onChange={(content) =>
    //                                         handleRowContentChange(
    //                                             row.id,
    //                                             content
    //                                         )
    //                                     }
    //                                 />
    //                             </TabPane>
    //                             <TabPane tab="Style" key="2">
    //                                 <Editor
    //                                     content={row.style}
    //                                     lang="css"
    //                                     onChange={(style) =>
    //                                         handleRowStyleChange(row.id, style)
    //                                     }
    //                                 />
    //                             </TabPane>
    //                         </Tabs>
    //                     </Panel>
    //                 ))}
    //             </Collapse>
    //         )}

    //     </Card>
    // );
};

export default PaperLayouts;
