import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    Typography,
    Divider,
    Button,
    Row,
    Col,
    message,
    Checkbox,
    Select,
} from "antd";

import { generateString } from "helpers/randomStringGenerator";

import http from "services/httpService";
import { apiUrl } from "config.json";

import { socket } from "layouts/Main";

const Notifications = (props) => {
    const [isEdit, setIsEdit] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(true);

    const [form] = Form.useForm();

    const { Title } = Typography;
    const { Option } = Select;

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    useEffect(() => {
        const notificationID = props.match.params.notification_id || null;
        const fetchData = async () => {
            try {
                setIsContentLoading(true);
                const response = await http.get(
                    `${apiUrl}/notification/${notificationID}`
                );
                const { notification } = response.data;
                console.log(notification);
                if (notification) {
                    delete notification.notification_id;
                    form.setFieldsValue({
                        ...notification,
                        broadcastRealtime: undefined,
                    });
                    setIsEdit(true);
                } else {
                    message.error("No Notification Found");
                }
                setIsContentLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        if (notificationID) {
            fetchData();
        } else {
            setIsContentLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFormFinish = async (formData) => {
        const isBroadcastRealtime = formData.broadcastRealtime;
        delete formData.broadcastRealtime;
        try {
            setIsSaving(true);
            if (isEdit) {
                const notificationID = props.match.params.notification_id;
                await http.put(
                    `${apiUrl}/master/notification/${notificationID}`,
                    formData
                );
                message.success("Notification saved!");
            } else {
                const newFormData = {
                    ...formData,
                    notification_id: generateString(10, "n"),
                };
                await http.post(`${apiUrl}/notification`, newFormData);
                if (isBroadcastRealtime) {
                    //emit
                    socket.emit("newNotification", newFormData);
                }
                message.success("Notification Successfully Created");
                form.resetFields();
            }
            setIsSaving(false);
        } catch (error) {
            message.error(error.message || "Something went wrong");
        }
    };

    return isContentLoading ? (
        <div>Loading</div>
    ) : (
        <div>
            <Title level={4}>Create Notification</Title>
            <div>
                To edit a notification add notification id to url
                (/notification_id). Take note that only notification_id on
                master only can be edited.
            </div>
            <Divider />
            <Row>
                <Col lg={20}>
                    <Form {...layout} form={form} onFinish={handleFormFinish}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Title is required!",
                                },
                            ]}
                        >
                            <Input placeholder="Advisory, Maintenance" />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: "Description is required!",
                                },
                                {
                                    max: 33,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: "Type is required!",
                                },
                            ]}
                        >
                            <Select>
                                <Option value="advisory">Advisory</Option>
                                <Option value="maintenance">Maintenance</Option>
                                <Option value="others">Others</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Icon"
                            name="icon"
                            rules={[
                                {
                                    required: true,
                                    message: "Icon is required!",
                                },
                            ]}
                        >
                            <Input placeholder="notification, tool, check" />
                        </Form.Item>
                        <Form.Item
                            label="Avatar BG Color"
                            name="avatar_bg_color"
                            rules={[
                                {
                                    required: true,
                                    message: "Icon is required!",
                                },
                            ]}
                        >
                            <Select>
                                <Option value="#87d068">Green</Option>
                                <Option value="#f56a00">Orange</Option>
                                <Option value="#f44336">Red</Option>
                                <Option value="#108ee9">Blue</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Show In"
                            name="show_in"
                            rules={[
                                {
                                    required: true,
                                    message: "Show In is required!",
                                },
                            ]}
                        >
                            <Select>
                                <Option value="route">Route</Option>
                                <Option value="url">Web Url</Option>
                                <Option value="modal">Modal</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Content"
                            name="content"
                            rules={[
                                {
                                    required: true,
                                    message: "Content is required!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            {...tailLayout}
                            name="broadcastRealtime"
                            valuePropName="checked"
                        >
                            <Checkbox>Broadcast realtime</Checkbox>
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button
                                type="primary"
                                loading={isSaving}
                                htmlType="submit"
                            >
                                Done
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col lg={4}></Col>
            </Row>
        </div>
    );
};

export default Notifications;
