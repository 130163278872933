import React, { useState, useEffect } from "react";
import {
    Button,
    Form,
    Empty,
    Space,
    Modal,
    message,
    Tabs,
    Input,
    Card,
} from "antd";
import {
    EyeOutlined,
    CheckOutlined,
    EditOutlined,
    ImportOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

import FormRenderBuilder from "./Components/FormRenderBuilder/Index";
import FormRender from "./Components/FormRender/Index";

import { generateString } from "helpers/randomStringGenerator";
import { saveData } from "helpers/autoSave";

const FormBuilder = () => {
    const [formProperties, setFormProperties] = useState({
        layout: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        },
        submitButton: {
            show: false,
            size: "medium",
            containerStyle: "",
        },
    });
    const [formRows, setFormRows] = useState([]);

    const [isFormRendererVisible, setIsFormRendererVisible] = useState(false);
    const [isGetDataVisible, setIsGetDataVisible] = useState(false);
    const [isLoadDataVisible, setIsLoadDataVisible] = useState(false);

    const [formJSONString, setFormJSONString] = useState("");

    const { confirm } = Modal;
    const { TabPane } = Tabs;
    const { TextArea } = Input;

    useEffect(() => {
        if (localStorage.getItem("formProps")) {
            setFormProperties(JSON.parse(localStorage.getItem("formProps")));
        }
        if (localStorage.getItem("formRows")) {
            setFormRows(JSON.parse(localStorage.getItem("formRows")));
        }
    }, []);

    const toggleFormRendererVisible = () => {
        setIsFormRendererVisible(!isFormRendererVisible);
    };

    const toggleGetDataVisible = () => {
        setIsGetDataVisible(!isGetDataVisible);
    };

    const toggleLoadDataVisible = () => {
        setIsLoadDataVisible(!isLoadDataVisible);
    };

    const handleFormPropertiesChange = (formProps) => {
        setFormProperties(formProps);
        saveData("formProps", formProps);
    };

    const handleFormRowsChange = (formRows) => {
        setFormRows(formRows);
        saveData("formRows", formRows);
    };

    const showData = () => {
        const formJSONString = {
            formProps: formProperties,
            formRows,
        };
        setFormJSONString(JSON.stringify(formJSONString));
        toggleGetDataVisible();
    };

    const clearForm = () => {
        confirm({
            title: "Are you sure do you want clear the entire form?",
            icon: <ExclamationCircleOutlined />,
            content: "This cannot be undone.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                setFormProperties({
                    layout: {
                        labelCol: { span: 8 },
                        wrapperCol: { span: 16 },
                    },
                    submitButton: {
                        show: false,
                        size: "medium",
                        containerStyle: "",
                    },
                });
                setFormRows([]);
                localStorage.removeItem("formProps");
                localStorage.removeItem("formRows");
            },
        });
    };

    const loadFormData = (values) => {
        if (values.formData) {
            try {
                const formData = JSON.parse(values.formData);
                const formProperties = formData.formProps;
                const formRows = formData.formRows;
                setFormProperties(formProperties);
                setFormRows(formRows);
                saveData("formProps", formProperties);
                saveData("formRows", formRows);
                toggleLoadDataVisible();
            } catch (error) {
                message.error("Unabled to parse form data json string");
                toggleLoadDataVisible();
            }
        }
    };

    const newForm = () => {
        const newRow = [
            {
                id: generateString(4, "r"),
                name: `row (${formRows.length + 1})`,
                style: "",
                cols: [
                    {
                        id: generateString(4, "c"),
                        name: "col (1)",
                        style: "",
                        width: {
                            xl: 6,
                            lg: 6,
                            md: 24,
                            sm: 24,
                            xs: 24,
                        },
                        formItems: [],
                    },
                ],
            },
        ];
        handleFormRowsChange(newRow);
    };

    return (
        <>
            {formRows.length === 0 ? (
                <div style={{ marginTop: 50 }}>
                    <Empty>
                        <Space>
                            <Button type="primary" onClick={newForm}>
                                <EditOutlined /> New Form
                            </Button>
                            <Button onClick={toggleLoadDataVisible}>
                                <ImportOutlined /> Load Form
                            </Button>
                        </Space>
                    </Empty>
                </div>
            ) : (
                <div>
                    <div style={{ marginBottom: 16 }}>
                        <Button.Group>
                            <Button
                                type="primary"
                                onClick={toggleFormRendererVisible}
                            >
                                <EyeOutlined /> Toggle Preview
                            </Button>
                            <Button onClick={showData}>
                                <CheckOutlined />
                                Get Data
                            </Button>
                            <Button onClick={clearForm}>
                                <EditOutlined />
                                Clear Form
                            </Button>
                            <Button onClick={toggleLoadDataVisible}>
                                <ImportOutlined /> Load Form
                            </Button>
                        </Button.Group>
                    </div>
                    <div>
                        {isFormRendererVisible ? (
                            <Card>
                                <FormRender
                                    formInstance={null}
                                    properties={formProperties}
                                    rows={formRows}
                                    onFinish={null}
                                />
                            </Card>
                        ) : (
                            <FormRenderBuilder
                                properties={formProperties}
                                rows={formRows}
                                onPropertiesChange={handleFormPropertiesChange}
                                onRowsChange={handleFormRowsChange}
                            />
                        )}
                    </div>
                </div>
            )}
            <Modal
                title="Load Data"
                visible={isLoadDataVisible}
                onCancel={toggleLoadDataVisible}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <Form layout="vertical" onFinish={loadFormData}>
                    <Form.Item name="formData" label="Form Data JSON String">
                        <TextArea rows={10} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Load
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Get Data"
                visible={isGetDataVisible}
                onCancel={toggleGetDataVisible}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="JSON String" key="1">
                        <TextArea rows={10} value={formJSONString} />
                    </TabPane>
                    <TabPane tab="React JSX" key="2">
                        <TextArea rows={10} value="SOON" />
                    </TabPane>
                </Tabs>
            </Modal>
        </>
    );
};

export default FormBuilder;
