import React, { useState, useEffect } from "react";
import {
    Form,
    Input,
    InputNumber,
    Select,
    Button,
    Drawer,
    Divider,
    Alert,
    message,
} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import FormRules from "./FormItemRules";

const FormProperties = ({ selectedColFormItem, onSubmit }) => {
    const [isRulesFormVisible, setIsRulesFormVisible] = useState(false);
    const [formItemRules, setFormItemRules] = useState([]);
    const [form] = Form.useForm(null);
    const [hasChanges, setHasChanges] = useState(false);

    const [selectedFormItemType, setSelectedFormItemType] = useState("");

    const { Option } = Select;
    const { TextArea } = Input;

    useEffect(() => {
        if (selectedColFormItem) {
            form.setFieldsValue(selectedColFormItem);
            setSelectedFormItemType(selectedColFormItem.type);
            setFormItemRules(selectedColFormItem.rules);
            setHasChanges(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColFormItem]);

    const toggleRulesFormVisible = () => {
        setIsRulesFormVisible(!isRulesFormVisible);
    };

    const getProperties = (type) => {
        if (type) {
            const properties = [
                {
                    property: "name",
                    component: (
                        <Form.Item
                            key={1}
                            label="name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "name is required!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    ),
                },
                {
                    property: "label",
                    component: (
                        <Form.Item key={2} label="label" name="label">
                            <Input />
                        </Form.Item>
                    ),
                },
                {
                    property: "value",
                    component: (
                        <Form.Item key={3} label="value" name="value">
                            <Input />
                        </Form.Item>
                    ),
                },
                {
                    property: "options",
                    component: (
                        <Form.Item key={4} label="options" name="options">
                            <TextArea placeholder="Separate you options by newline" />
                        </Form.Item>
                    ),
                },
                {
                    property: "placeholder",
                    component: (
                        <Form.Item
                            key={5}
                            label="placeholder"
                            name="placeholder"
                        >
                            <Input />
                        </Form.Item>
                    ),
                },
                {
                    property: "transform",
                    component: (
                        <React.Fragment key={6}>
                            <Form.Item label="transform" name="transform">
                                <Select>
                                    <Option value="normal">Normal</Option>
                                    <Option value="titleCase">
                                        Title Case
                                    </Option>
                                    <Option value="upperCase">
                                        Upper Case
                                    </Option>
                                    <Option value="lowerCase">
                                        Lower Case
                                    </Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                {...tailLayout}
                                style={{ marginBottom: 0 }}
                            >
                                <p>
                                    Note: text transform will only effect on
                                    live preview.
                                </p>
                            </Form.Item>
                        </React.Fragment>
                    ),
                },
                {
                    property: "rules",
                    component: (
                        <Form.Item key={7} label="Rules">
                            {formItemRules.length !== 0 && (
                                <div style={{ marginBottom: 8 }}>
                                    {formItemRules.map((rule) => (
                                        <div key={Object.keys(rule)[0]}>
                                            <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                                            {Object.keys(rule)[0]} (
                                            {rule[
                                                Object.keys(rule)[0]
                                            ].toString()}
                                            )
                                        </div>
                                    ))}
                                </div>
                            )}
                            <Button onClick={toggleRulesFormVisible}>
                                Set Rules
                            </Button>
                        </Form.Item>
                    ),
                },
                {
                    property: "offset",
                    component: (
                        <Form.Item
                            key={8}
                            label="Offset"
                            name="offset"
                            initialValue={selectedColFormItem.offset}
                        >
                            <InputNumber />
                        </Form.Item>
                    ),
                },
                {
                    property: "containerStyle",
                    component: (
                        <Form.Item
                            key={9}
                            label="Container Style"
                            name="containerStyle"
                        >
                            <TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                placeholder="css code"
                            />
                        </Form.Item>
                    ),
                },
                {
                    property: "style",
                    component: (
                        <Form.Item key={10} label="Style" name="style">
                            <TextArea
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                placeholder="css code"
                            />
                        </Form.Item>
                    ),
                },
                {
                    property: "min",
                    component: (
                        <Form.Item key={11} label="Min" name="min">
                            <InputNumber />
                        </Form.Item>
                    ),
                },
                {
                    property: "max",
                    component: (
                        <Form.Item key={12} label="Max" name="max">
                            <InputNumber />
                        </Form.Item>
                    ),
                },
                {
                    property: "gridColSpan",
                    component: (
                        <Form.Item
                            key={13}
                            label="Grid Col Span"
                            name="gridColSpan"
                        >
                            <InputNumber />
                        </Form.Item>
                    ),
                },
            ];
            const itemPropertiesMap = [
                {
                    type: "text",
                    properties: ["name", "label", "containerStyle", "style"],
                },
                {
                    type: "title",
                    properties: ["name", "label", "containerStyle"],
                },
                {
                    type: "divider",
                    properties: ["name", "containerStyle"],
                },
                {
                    type: "input",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "placeholder",
                        "rules",
                        "transform",
                        "offset",
                        "containerStyle",
                    ],
                },
                {
                    type: "inputNumber",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "placeholder",
                        "rules",
                        "transform",
                        "offset",
                        "containerStyle",
                        "min",
                        "max",
                    ],
                },
                {
                    type: "textarea",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "placeholder",
                        "rules",
                        "transform",
                        "offset",
                        "containerStyle",
                        "min",
                        "max",
                    ],
                },
                {
                    type: "select",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "options",
                        "placeholder",
                        "rules",
                        "offset",
                        "containerStyle",
                    ],
                },
                {
                    type: "selectMultiple",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "options",
                        "placeholder",
                        "rules",
                        "offset",
                        "containerStyle",
                    ],
                },
                {
                    type: "radio",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "options",
                        "rules",
                        "offset",
                        "containerStyle",
                        "gridColSpan",
                    ],
                },
                {
                    type: "checkbox",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "options",
                        "rules",
                        "offset",
                        "containerStyle",
                        "gridColSpan",
                    ],
                },
                {
                    type: "switch",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "rules",
                        "offset",
                        "containerStyle",
                    ],
                },
                {
                    type: "slider",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "rules",
                        "offset",
                        "containerStyle",
                        "min",
                        "max",
                    ],
                },
                {
                    type: "datepicker",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "placeholder",
                        "rules",
                        "offset",
                        "containerStyle",
                    ],
                },
                {
                    type: "upload",
                    properties: [
                        "name",
                        "label",
                        "value",
                        "rules",
                        "offset",
                        "containerStyle",
                    ],
                },
            ];

            const selectedTypeProperties = itemPropertiesMap.find(
                (item) => item.type === type
            ).properties;

            const itemProperties = properties
                .filter(
                    (property) =>
                        selectedTypeProperties.indexOf(property.property) !== -1
                )
                .map((property) => property.component);

            return itemProperties;
        } else {
            return null;
        }
    };

    const handleFormRuleSubmit = (rules) => {
        setFormItemRules(rules);
        toggleRulesFormVisible();
        setHasChanges(true);
    };

    const handleFormValuesChange = (changedValues, allValues) => {
        if (Object.keys(changedValues)[0] === "type") {
            setSelectedFormItemType(changedValues.type);
        }
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const handleFormFinish = (formValues) => {
        formValues.rules = formItemRules;

        let newOptions = formValues.options;
        if (newOptions) {
            newOptions = newOptions
                .split("\n")
                .map((o) => o.trim())
                .filter((o) => o !== "")
                .map((o) => o);

            if (new Set(newOptions).size !== newOptions.length) {
                message.error("Duplicate options found");
            } else {
                formValues.options = newOptions.join("\n");
                setHasChanges(false);
                console.log(formValues);
                onSubmit(formValues);
            }
        } else {
            setHasChanges(false);
            console.log(formValues);
            onSubmit(formValues);
        }
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    return (
        <div className="site-drawer-render-in-current-wrapper">
            {hasChanges && (
                <Alert
                    type="warning"
                    message="Save to apply changes"
                    showIcon
                    style={{ marginBottom: 16 }}
                />
            )}
            <Form
                {...layout}
                form={form}
                onFinish={handleFormFinish}
                onValuesChange={handleFormValuesChange}
            >
                <Form.Item
                    label="type"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: "type is required!",
                        },
                    ]}
                >
                    <Select>
                        <Option value="text">Text</Option>
                        <Option value="title">Title</Option>
                        <Option value="divider">Divider</Option>
                        <Option value="input">Input</Option>
                        <Option value="inputNumber">Input Number</Option>
                        <Option value="textarea">Textarea</Option>
                        <Option value="select">Select</Option>
                        <Option value="selectMultiple">Select Multiple</Option>
                        <Option value="radio">Options (Single Select)</Option>
                        <Option value="checkbox">Options (Multi Select)</Option>
                        <Option value="switch">Switch</Option>
                        <Option value="slider">Slider</Option>
                        <Option value="datepicker">Date Picker</Option>
                        <Option value="upload">Upload</Option>
                    </Select>
                </Form.Item>
                {getProperties(selectedFormItemType)}
                <Divider />
                <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                    <Button type="primary" htmlType="submit">
                        Save Properties
                    </Button>
                </Form.Item>
            </Form>
            <Drawer
                title="Select Rules"
                placement="right"
                onClose={toggleRulesFormVisible}
                visible={isRulesFormVisible}
                getContainer={false}
                style={{ position: "absolute" }}
                bodyStyle={{ padding: 0 }}
                destroyOnClose
            >
                <FormRules onSubmit={handleFormRuleSubmit} />
            </Drawer>
        </div>
    );
};

export default FormProperties;
