export const CSSToReactStyle = (input) => {
    if (input) {
        const cssLines = input.split("\n");
        let output = {};
        let cssText = "";
        let cssProp = "";
        let cssVal = "";
        cssLines.forEach((cssLine) => {
            cssText = cssLine.replace(";", "");
            cssText = cssText.replace(/(-.)/g, function (v) {
                return v[1].toUpperCase();
            });
            cssProp = cssText.split(":")[0].trim();
            cssVal = cssText.split(":")[1].trim();
            output[cssProp] = cssVal;
        });
        return output;
    } else {
        return {};
    }
};
