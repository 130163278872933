import {
    GLOBAL_STATE_FETCHING_START,
    GLOBAL_STATE_FETCHING_END,
    REQUEST_ERROR,
} from "./system.types";

const INIT_STATE = {
    isRequestError: false,
    requestErrorMsg: "",
    requestErrorSubMsg: "",
    isStateFetching: true,
};

const systemReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GLOBAL_STATE_FETCHING_START:
            return {
                ...state,
                isStateFetching: true,
            };
        case GLOBAL_STATE_FETCHING_END:
            return {
                ...state,
                isStateFetching: false,
            };
        case REQUEST_ERROR:
            return {
                ...state,
                isRequestError: true,
                requestErrorMsg: action.payload.errorMessage,
                requestErrorSubMsg: action.payload.errorSubMessage,
            };
        default:
            return state;
    }
};

export default systemReducer;
