import React from "react";
import {
    Layout,
    Dropdown,
    Menu,
    Row,
    Col,
    Avatar,
    Button,
    Typography,
} from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";

const Topbar = ({ breakpoint, title }) => {
    const { Header } = Layout;
    const { Title } = Typography;

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() => {
                    localStorage.removeItem("token");
                    window.location = `/`;
                }}
            >
                <LogoutOutlined /> Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ padding: "0 16px", backgroundColor: "#fff" }}>
            <Row type="flex" justify="space-between" align="middle">
                <Col>
                    <Title level={4}>{title}</Title>
                </Col>
                <Col>
                    <Dropdown
                        overlay={menu}
                        placement="bottomRight"
                        trigger={["click"]}
                    >
                        <Button
                            style={{
                                height: 55,
                                border: "none",
                            }}
                        >
                            <Avatar
                                icon={<UserOutlined />}
                                style={{
                                    color: "#2196F3",
                                    backgroundColor: "#ddf0ff",
                                    marginRight: 10,
                                }}
                            />
                            Welcome Admin
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    );
};

export default Topbar;
