import React from "react";
import {
    Form,
    Input,
    InputNumber,
    Select,
    Checkbox,
    Radio,
    Row,
    Col,
    DatePicker,
    Switch,
    Slider,
    Typography,
    Divider,
    Upload,
    Button,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { CSSToReactStyle } from "helpers/simpleCSSToReactStyle";

const FormItemRenderer = ({ formItems }) => {
    const { Option } = Select;
    const { TextArea } = Input;
    const { Title } = Typography;

    return formItems.map((formItem) => {
        const {
            type,
            name,
            label,
            value,
            options,
            placeholder,
            rules,
            offset,
            containerStyle,
        } = formItem;
        switch (type) {
            case "text":
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <span
                            style={
                                formItem.style
                                    ? CSSToReactStyle(formItem.style)
                                    : null
                            }
                        >
                            {label}
                        </span>
                    </div>
                );
            case "title":
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Title level={4}>{label}</Title>
                    </div>
                );
            case "divider":
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Divider />
                    </div>
                );
            case "input":
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Input placeholder={placeholder} />
                        </Form.Item>
                    </div>
                );
            case "inputNumber":
                let minNumber = Number.MIN_SAFE_INTEGER;
                let maxNumber = Number.MAX_SAFE_INTEGER;
                if (formItem.min) {
                    minNumber = formItem.min;
                }
                if (formItem.max) {
                    maxNumber = formItem.max;
                }
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <InputNumber min={minNumber} max={maxNumber} />
                        </Form.Item>
                    </div>
                );
            case "textarea":
                let minRows = 2;
                let maxRows = 6;
                if (formItem.min) {
                    minRows = formItem.min;
                }
                if (formItem.max) {
                    maxRows = formItem.max;
                }
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <TextArea
                                placeholder={placeholder}
                                autoSize={{
                                    minRows: minRows,
                                    maxRows: maxRows,
                                }}
                            />
                        </Form.Item>
                    </div>
                );
            case "select":
                const newSelectOptions = options.split("\n").map((item) => {
                    return { label: item, value: item };
                }, []);
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Select>
                                {newSelectOptions.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                );
            case "selectMultiple":
                const newSelectMultipleOptions = options
                    .split("\n")
                    .map((item) => {
                        return { label: item, value: item };
                    }, []);
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={[]}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Select mode="multiple">
                                {newSelectMultipleOptions.map((option) => (
                                    <Option
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                );
            case "radio":
                const newRadioOptions = options.split("\n");
                const radioStyle = {
                    display: "block",
                    height: "30px",
                    lineHeight: "30px",
                };
                let radioGridColSpan = 24;
                if (formItem.gridColSpan) {
                    radioGridColSpan = formItem.gridColSpan;
                }
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            //valuePropName="checked"
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Radio.Group
                                style={{ width: "100%" }}
                                // optionType="button"
                                // buttonStyle="solid"
                            >
                                <Row>
                                    {newRadioOptions.map((option) => (
                                        <Col
                                            span={radioGridColSpan}
                                            key={option}
                                        >
                                            <Radio
                                                key={option}
                                                value={option}
                                                style={radioStyle}
                                            >
                                                {option}
                                            </Radio>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                );
            case "checkbox":
                const newCheckboxOptions = options.split("\n");
                let checkboxGridColSpan = 24;
                if (formItem.gridColSpan) {
                    checkboxGridColSpan = formItem.gridColSpan;
                }
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            valuePropName="checked"
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Checkbox.Group style={{ width: "100%" }}>
                                <Row>
                                    {newCheckboxOptions.map((option) => (
                                        <Col
                                            span={checkboxGridColSpan}
                                            key={option}
                                        >
                                            <Checkbox value={option}>
                                                {option}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                );
            case "switch":
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                );
            case "slider":
                let minSlideNumber = 0;
                let maxSlideNumber = 100;
                if (formItem.min) {
                    minSlideNumber = formItem.min;
                }
                if (formItem.max) {
                    maxSlideNumber = formItem.max;
                }
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Slider min={minSlideNumber} max={maxSlideNumber} />
                        </Form.Item>
                    </div>
                );
            case "datepicker":
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            initialValue={value}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <DatePicker />
                        </Form.Item>
                    </div>
                );
            case "upload":
                const normFile = (e) => {
                    //console.log("Upload event:", e);
                    if (Array.isArray(e)) {
                        return e;
                    }
                    return e && e.fileList;
                };
                return (
                    <div
                        key={name}
                        style={
                            containerStyle
                                ? CSSToReactStyle(containerStyle)
                                : null
                        }
                    >
                        <Form.Item
                            name={name}
                            label={label}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={rules}
                            wrapperCol={
                                offset !== 0
                                    ? {
                                          offset: offset,
                                          span: 24 - offset,
                                      }
                                    : null
                            }
                        >
                            <Upload
                                name="logo"
                                listType="picture"
                                beforeUpload={(file) => {
                                    return false;
                                }}
                            >
                                <Button>
                                    <UploadOutlined /> Click to upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </div>
                );
            default:
                return null;
        }
    });
};

export default FormItemRenderer;
