import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Empty } from "antd";

import jwtDecode from "jwt-decode";

import ContentLoader from "globalComponents/ContentLoader";
import Conversations from "globalComponents/Chat/Conversations";
import ConversationMessagesHeader from "globalComponents/Chat/ConversationMessagesHeader";
import ConversationMessages from "globalComponents/Chat/ConversationMessages";
import ChatForm from "globalComponents/Chat/ChatForm";

import { socket } from "layouts/Main";

import http from "services/httpService";
import { apiUrl } from "config.json";

//axios cancellation
const CancelToken = http.CancelToken;
let CancelRequest = undefined;

const Messages = (props) => {
    //const { clinics } = props;
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [selectedConversationMsgs, setSelectedConversationMsgs] = useState(
        []
    );

    const [isConversationLoading, setIsConversationLoading] = useState(false);
    const [getMessagesOffest, setGetMessagesOffest] = useState(10);

    //for scrolling behavior
    const [moveScrollToBottomWatcher, setMoveScrollToBottomWatcher] = useState(
        0
    );
    const [moveScrollWatcher, setMoveScrollWatcher] = useState(0);

    const [serverDate, setServerDate] = useState(null);

    const currentUserID = "support";

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const token = localStorage.getItem("token");
                const serverDate = token ? jwtDecode(token) : null;

                const response = await http.get(
                    `${apiUrl}/chat/admin/conversations/${currentUserID}`,
                    {
                        cancelToken: new CancelToken(function executor(c) {
                            CancelRequest = c;
                        }),
                    }
                );
                const { users, messages } = response.data;
                const groupedMessages = [];

                //group messages by users
                users.forEach((user) => {
                    const userID = user.id;
                    const clinicID = user.clinic_id;

                    const filteredMessages = messages.filter((message) => {
                        const { sender_id, receiver_id, clinic_id } = message;
                        return (
                            (userID === sender_id || userID === receiver_id) &&
                            clinicID === clinic_id
                        );
                    });

                    if (filteredMessages.length !== 0) {
                        let totalUnreadMessage = filteredMessages.filter(
                            (message) =>
                                message.is_read === 0 &&
                                message.sender_id !== "support"
                        ).length;

                        let recentMessage =
                            filteredMessages[filteredMessages.length - 1];
                        if (recentMessage.sender_id === "support") {
                            recentMessage = {
                                ...recentMessage,
                                message: `You: ${recentMessage.message}`,
                            };
                        }

                        groupedMessages.push({
                            userID,
                            clinicID,
                            recentMessage,
                            totalUnreadMessage,
                        });
                    }
                });

                //build the list of conversation
                let newConversations = [];
                users.forEach((user) => {
                    const data = groupedMessages.filter(
                        (message) =>
                            message.userID === user.id &&
                            message.clinicID === user.clinic_id
                    );
                    let recentMessage = null;
                    let totalUnreadMessage = 0;

                    if (data.length !== 0) {
                        recentMessage = data[0].recentMessage;
                        totalUnreadMessage = data[0].totalUnreadMessage;
                    }

                    if (recentMessage && recentMessage.is_read === 0) {
                        newConversations.unshift({
                            user_id: user.id,
                            name:
                                user.type === "staff"
                                    ? user.staff_name
                                    : user.doctor_name,
                            clinic_name: user.clinic_name,
                            clinic_id: user.clinic_id,
                            owner_id: user.owner_id,
                            img_path: "",
                            recentMessage,
                            totalUnreadMessage,
                        });
                    } else {
                        newConversations.push({
                            user_id: user.id,
                            name:
                                user.type === "staff"
                                    ? user.staff_name
                                    : user.doctor_name,
                            clinic_name: user.clinic_name,
                            clinic_id: user.clinic_id,
                            owner_id: user.owner_id,
                            img_path: "",
                            recentMessage,
                            totalUnreadMessage,
                            //token: user.token,
                        });
                    }
                });

                setConversations(newConversations);
                setServerDate(serverDate);
                setIsContentLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchConversations();

        return () => {
            if (CancelRequest) {
                CancelRequest({
                    responseCancelled: true,
                });
            }
        };
    }, []);

    useEffect(() => {
        socket.on("messageReceived", async function (messageData) {
            if (document.hidden) {
                document.title = "(1) New Message";
            }

            let isUpdateMessageStatus = false;
            let topConversation = null;
            let newConversations = conversations.map((conversation) => {
                if (
                    conversation.user_id === messageData.sender_id.toString() &&
                    conversation.clinic_id === messageData.clinic_id.toString()
                ) {
                    let totalUnreadMessage = 0;
                    let is_read = 1;
                    if (!selectedConversation) {
                        totalUnreadMessage =
                            conversation.totalUnreadMessage + 1;
                        is_read = 0;
                    } else if (
                        selectedConversation.user_id !==
                        messageData.sender_id.toString()
                    ) {
                        totalUnreadMessage =
                            conversation.totalUnreadMessage + 1;
                        is_read = 0;
                    } else {
                        isUpdateMessageStatus = true;
                    }

                    topConversation = {
                        ...conversation,
                        recentMessage: { ...messageData, is_read },
                        totalUnreadMessage,
                    };

                    return null;
                }
                return conversation;
            });

            if (
                selectedConversation &&
                selectedConversation.user_id ===
                    messageData.sender_id.toString()
            ) {
                setSelectedConversationMsgs([
                    ...selectedConversationMsgs,
                    messageData,
                ]);
            }

            newConversations = [topConversation, ...newConversations];
            newConversations = newConversations.filter((c) => c);

            setConversations(newConversations);
            setMoveScrollToBottomWatcher(moveScrollToBottomWatcher + 1);

            //Sound.src = chatSound;
            //Sound.play();

            try {
                if (isUpdateMessageStatus) {
                    const { sender_id, receiver_id } = messageData;
                    await http.put(`${apiUrl}/chat/clinic/update/message`, {
                        sender_id,
                        receiver_id,
                    });
                }
            } catch (error) {}
        });

        return () => {
            socket.off("messageReceived");
        };
    }, [
        conversations,
        selectedConversation,
        selectedConversationMsgs,
        moveScrollToBottomWatcher,
    ]);

    const handleConversationChange = async (selectedConvo) => {
        try {
            const { user_id, clinic_id, totalUnreadMessage } = selectedConvo;

            setSelectedConversation(selectedConvo);
            setSelectedConversationMsgs([]);
            setIsConversationLoading(true);
            const response = await http.get(
                `${apiUrl}/chat/clinic/conversation/messages/${clinic_id}/${currentUserID}/${user_id}/${totalUnreadMessage}/0`
            );

            const newConversations = conversations.map((conversation) => {
                if (
                    conversation.user_id === selectedConvo.user_id &&
                    conversation.clinic_id === selectedConvo.clinic_id
                ) {
                    return {
                        ...conversation,
                        recentMessage: conversation.recentMessage
                            ? {
                                  ...conversation.recentMessage,
                                  is_read: 1,
                              }
                            : null,
                        totalUnreadMessage: 0,
                    };
                }
                return conversation;
            });

            setSelectedConversationMsgs(
                response.data.messages.sort(
                    (a, b) => a.message_id - b.message_id
                )
            );
            setConversations(newConversations);
            setGetMessagesOffest(10);
            setMoveScrollToBottomWatcher(moveScrollToBottomWatcher + 1);
            setIsConversationLoading(false);
        } catch (error) {}
    };

    const handleChatSubmit = async (message) => {
        //update recent message of selected conversation
        let topConversation = null;
        let newConversations = conversations.map((conversation) => {
            if (
                conversation.user_id === selectedConversation.user_id &&
                conversation.clinic_id === selectedConversation.clinic_id
            ) {
                let recentMessage = message;
                if (recentMessage.sender_id === currentUserID) {
                    recentMessage = {
                        ...recentMessage,
                        message: `You: ${recentMessage.message}`,
                    };
                }

                topConversation = {
                    ...conversation,
                    recentMessage,
                };

                return null;
            }
            return conversation;
        });

        newConversations = [topConversation, ...newConversations];
        newConversations = newConversations.filter((c) => c);

        setConversations(newConversations);
        setSelectedConversationMsgs([...selectedConversationMsgs, message]);
        setMoveScrollToBottomWatcher(moveScrollToBottomWatcher + 1);
        //insert to admin
        await http.post(`${apiUrl}/chat/admin`, message);

        //insert to client
        await http.post(`${apiUrl}/chat`, message, {
            headers: {
                conn: selectedConversation.owner_id,
            },
        });

        //emit
        socket.emit("messageSend", message);
    };

    const handleScrolledToTop = async () => {
        try {
            const response = await http.get(
                `${apiUrl}/chat/clinic/conversation/messages/${selectedConversation.clinic_id}/${currentUserID}/${selectedConversation.user_id}/0/${getMessagesOffest}`
            );

            const messages = response.data.messages.sort(
                (a, b) => a.message_id - b.message_id
            );

            if (messages.length !== 0) {
                setSelectedConversationMsgs([
                    ...messages,
                    ...selectedConversationMsgs,
                ]);
                setGetMessagesOffest(getMessagesOffest + 10);
            }
            setMoveScrollWatcher(moveScrollWatcher + 1);
        } catch (error) {}
    };

    return isContentLoading ? (
        <ContentLoader />
    ) : (
        <>
            <Row gutter={8}>
                <Col xl={8} lg={8}>
                    <Conversations
                        conversations={conversations}
                        currentUserID={currentUserID}
                        serverDate={serverDate}
                        onChange={handleConversationChange}
                    />
                </Col>
                <Col xl={16} lg={16}>
                    {selectedConversation ? (
                        <>
                            <ConversationMessagesHeader
                                conversationData={selectedConversation}
                            />
                            <ConversationMessages
                                currentUserID={currentUserID}
                                conversationData={selectedConversation}
                                messages={selectedConversationMsgs}
                                loading={isConversationLoading}
                                moveScrollWatcher={moveScrollWatcher}
                                moveScrollToBottomWatcher={
                                    moveScrollToBottomWatcher
                                }
                                onScrolledToTop={handleScrolledToTop}
                            />
                            <ChatForm
                                currentUserID={currentUserID}
                                receiverID={selectedConversation.user_id}
                                clinicID={selectedConversation.clinic_id}
                                onSubmit={handleChatSubmit}
                            />
                        </>
                    ) : (
                        <div style={{ paddingTop: 50 }}>
                            <Empty description="No Conversation Selected" />
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => ({
    clinics: state.Clinics,
});

export default connect(mapStateToProps)(Messages);

//export { socket };
