import React, { useState, useEffect } from "react";
import {
    Button,
    Form,
    Card,
    Row,
    Col,
    Tooltip,
    Space,
    Menu,
    Dropdown,
    Modal,
    Alert,
    Popover,
    message,
} from "antd";
import {
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";

import FormRowProperties from "./Components/Forms/FormRowProperties";
import FormMoveRows from "./Components/Forms/FormMoveRows";
import FormColProperties from "./Components/Forms/FormColProperties";
import FormMoveCols from "./Components/Forms/FormMoveCols";
import FormManageColItems from "./Components/Forms/FormManageColItems/Index";
import FormFormProperties from "./Components/Forms/FormFormProperties";

import FormItemRender from "../FormRender/Components/FormItemRender";

import { generateString } from "helpers/randomStringGenerator";
import { CSSToReactStyle } from "helpers/simpleCSSToReactStyle";

const FormRenderBuilder = ({
    properties,
    rows,
    onPropertiesChange,
    onRowsChange,
}) => {
    const [form] = Form.useForm(null);

    const [formProperties, setFormProperties] = useState({
        layout: {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        },
        submitButton: {
            show: false,
            size: "medium",
            containerStyle: "",
        },
    });
    const [formRows, setFormRows] = useState([]);

    const [selectedFormRow, setSelectedFormRow] = useState(null);
    const [selectedFormRowCol, setSelectedFormRowCol] = useState(null);

    const [isFormRowPropVisible, setIsFormRowPropVisible] = useState(false);
    const [isFormColPropVisible, setIsFormColPropVisible] = useState(false);
    const [isFormMoveRowVisible, setIsFormMoveRowVisible] = useState(false);
    const [isFormMoveColVisible, setIsFormMoveColVisible] = useState(false);
    const [isFormManageItemsVisible, setIsFormManageItemsVisible] = useState(
        false
    );
    const [isFormPropVisible, setIsFormPropVisible] = useState(false);

    const { confirm } = Modal;

    useEffect(() => {
        setFormProperties(properties);
        setFormRows(rows);
    }, [properties, rows]);

    useEffect(() => {
        window.addEventListener("keydown", keydown);
        return () => {
            window.removeEventListener("keydown", keydown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFormRow, selectedFormRowCol, formRows]);

    function keydown(e) {
        if (e.keyCode === 82 && e.altKey && e.shiftKey) {
            if (selectedFormRow && selectedFormRowCol) {
                deleteFormRow(selectedFormRow.id);
            }
        } else if (e.keyCode === 67 && e.altKey && e.shiftKey) {
            if (selectedFormRow && selectedFormRowCol) {
                deleteFormRowCol(selectedFormRow.id, selectedFormRowCol.id);
            }
        } else if (e.keyCode === 82 && e.altKey) {
            if (selectedFormRow && selectedFormRowCol) {
                addFormRow(selectedFormRow.id, "after");
            }
        } else if (e.keyCode === 67 && e.altKey) {
            if (selectedFormRow && selectedFormRowCol) {
                addFormRowCol(
                    selectedFormRow.id,
                    selectedFormRowCol.id,
                    "after"
                );
            }
        } else if (e.keyCode === 87 && e.altKey) {
            toggleFormMoveRowVisible();
        } else if (e.keyCode === 81 && e.altKey) {
            if (selectedFormRow && selectedFormRowCol) {
                showFormMoveCol(selectedFormRow.id);
            }
        } else if (e.keyCode === 65 && e.altKey) {
            if (selectedFormRow && selectedFormRowCol) {
                showFormItems(selectedFormRow.id, selectedFormRowCol.id);
            }
        }
    }

    const showSelected = (formRowId, formRowColId) => {
        const row = formRows.find((formRow) => formRow.id === formRowId);
        const col = row.cols.find((col) => col.id === formRowColId);
        setSelectedFormRow(row);
        setSelectedFormRowCol(col);
    };

    //#region ROW EVENTS

    const toggleFormRowPropVisible = () => {
        setIsFormRowPropVisible(!isFormRowPropVisible);
    };

    const toggleFormMoveRowVisible = () => {
        setIsFormMoveRowVisible(!isFormMoveRowVisible);
    };

    const addFormRow = (formRowId = null, insertTo = "") => {
        let newFormRows = [];
        const col1 = {
            id: generateString(4, "r"),
            name: `row (${formRows.length + 1})`,
            style: "",
            cols: [
                {
                    id: generateString(4, "c"),
                    name: "col (1)",
                    style: "",
                    width: {
                        xl: 6,
                        lg: 6,
                        md: 24,
                        sm: 24,
                        xs: 24,
                    },
                    formItems: [],
                },
            ],
        };
        if (formRowId) {
            const formRowIndex = formRows.findIndex(
                (formRow) => formRow.id === formRowId
            );
            if (formRowIndex === 0 && insertTo === "before") {
                newFormRows = [col1, ...formRows];
            } else if (insertTo === "before") {
                newFormRows = [...formRows];
                newFormRows.splice(formRowIndex, 0, col1);
            } else {
                newFormRows = [...formRows];
                newFormRows.splice(formRowIndex + 1, 0, col1);
            }
        } else {
            newFormRows = [...formRows, col1];
        }
        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
    };

    const deleteFormRow = (formRowId) => {
        confirm({
            title: "Are you sure do you want delete selected row?",
            icon: <ExclamationCircleOutlined />,
            content: "This cannot be undone.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                const newFormRows = formRows.filter(
                    (formRow) => formRow.id !== formRowId
                );
                setFormRows(newFormRows);
                //saveData("formRows", newFormRows);
                onRowsChange(newFormRows);
            },
        });
    };

    const sortFormRows = (newFormRows) => {
        toggleFormMoveRowVisible();
        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
    };

    const showFormRowProp = (formRowId) => {
        const row = formRows.find((formRow) => formRow.id === formRowId);
        setSelectedFormRow(row);
        toggleFormRowPropVisible();
    };

    const handleFormRowPropertiesFinish = (formData) => {
        const newFormRows = formRows.map((formRow) => {
            if (formRow.id === selectedFormRow.id) {
                return {
                    ...formRow,
                    ...formData,
                };
            }
            return formRow;
        });
        toggleFormRowPropVisible();
        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
    };

    //#endregion

    //#region COL EVENTS

    const toggleFormColPropVisible = () => {
        setIsFormColPropVisible(!isFormColPropVisible);
    };

    const toggleFormMoveColVisible = () => {
        setIsFormMoveColVisible(!isFormMoveColVisible);
    };

    const addFormRowCol = (formRowId, formRowColId, insertTo) => {
        const formRow = formRows.find((formRow) => formRow.id === formRowId);
        const formRowCols = formRow.cols;
        const formRowColIndex = formRowCols.findIndex(
            (formRowCol) => formRowCol.id === formRowColId
        );

        const newCol = {
            id: generateString(4, "c"),
            name: `col (${formRowCols.length + 1})`,
            style: "",
            width: {
                xl: 6,
                lg: 6,
                md: 24,
                sm: 24,
                xs: 24,
            },
            formItems: [],
        };
        let newFormRowCols = [];
        if (formRowColIndex === 0 && insertTo === "before") {
            newFormRowCols = [newCol, ...formRowCols];
        } else if (insertTo === "before") {
            newFormRowCols = [...formRowCols];
            newFormRowCols.splice(formRowColIndex, 0, newCol);
        } else {
            newFormRowCols = [...formRowCols];
            newFormRowCols.splice(formRowColIndex + 1, 0, newCol);
        }

        const newFormRows = formRows.map((formRow) => {
            if (formRow.id === formRowId) {
                return {
                    ...formRow,
                    cols: newFormRowCols,
                };
            }
            return formRow;
        });

        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
    };

    const deleteFormRowCol = (formRowId, formRowColId) => {
        const colCount = formRows.find((formRow) => formRow.id === formRowId)
            .cols.length;

        if (colCount === 1) {
            Modal.info({
                title: "Unabled to delete column",
                content: "Row must have atleast one column.",
                onOk() {},
            });
        } else {
            confirm({
                title: "Are you sure do you want delete selected column?",
                icon: <ExclamationCircleOutlined />,
                content: "This cannot be undone.",
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                    const newFormRows = formRows.map((formRow) => {
                        if (formRow.id === formRowId) {
                            return {
                                ...formRow,
                                cols: formRow.cols.filter(
                                    (formRowCol) =>
                                        formRowCol.id !== formRowColId
                                ),
                            };
                        }
                        return formRow;
                    });
                    setFormRows(newFormRows);
                    onRowsChange(newFormRows);
                    //saveData("formRows", newFormRows);
                },
            });
        }
    };

    const sortFormRowCols = (newFormRowCols) => {
        const newFormRows = formRows.map((formRow) => {
            if (formRow.id === selectedFormRow.id) {
                return {
                    ...formRow,
                    cols: newFormRowCols,
                };
            }
            return formRow;
        });
        toggleFormMoveColVisible();
        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
    };

    const showFormColProp = (formRowId, formRowColId) => {
        const row = formRows.find((formRow) => formRow.id === formRowId);
        const col = row.cols.find((col) => col.id === formRowColId);
        setSelectedFormRow(row);
        setSelectedFormRowCol(col);
        toggleFormColPropVisible();
    };

    const showFormMoveCol = (formRowId) => {
        const row = formRows.find((formRow) => formRow.id === formRowId);
        setSelectedFormRow(row);
        toggleFormMoveColVisible();
    };

    const handleFormColPropertiesFinish = (formData) => {
        const newFormRows = formRows.map((formRow) => {
            if (formRow.id === selectedFormRow.id) {
                const newCols = formRow.cols.map((formCol) => {
                    if (formCol.id === selectedFormRowCol.id) {
                        return {
                            ...formCol,
                            name: formData.name,
                            style: formData.style,
                            width: {
                                xl: formData.xl,
                                lg: formData.lg,
                                md: formData.md,
                                sm: formData.sm,
                                xs: formData.xs,
                            },
                        };
                    }
                    return formCol;
                });

                return {
                    ...formRow,
                    cols: newCols,
                };
            }
            return formRow;
        });
        toggleFormColPropVisible();
        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
    };

    //#endregion

    //#region ITEMS EVENTS

    const toggleFormManageItemsVisible = () => {
        setIsFormManageItemsVisible(!isFormManageItemsVisible);
    };

    const showFormItems = (formRowId, formRowColId) => {
        const row = formRows.find((formRow) => formRow.id === formRowId);
        const col = row.cols.find((col) => col.id === formRowColId);
        setSelectedFormRow(row);
        setSelectedFormRowCol(col);
        toggleFormManageItemsVisible();
    };

    const handleFormRowColItemsFinish = (formItems) => {
        const newFormRows = formRows.map((formRow) => {
            if (formRow.id === selectedFormRow.id) {
                const newFormRowCols = formRow.cols.map((formRowCol) => {
                    if (formRowCol.id === selectedFormRowCol.id) {
                        return {
                            ...formRowCol,
                            formItems: formItems,
                        };
                    }
                    return formRowCol;
                });

                return {
                    ...formRow,
                    cols: newFormRowCols,
                };
            }
            return formRow;
        });
        setFormRows(newFormRows);
        onRowsChange(newFormRows);
        //saveData("formRows", newFormRows);
        toggleFormManageItemsVisible();
    };

    //#endregion

    //#region FORM EVENTS
    const toggleFormPropVisible = () => {
        setIsFormPropVisible(!isFormPropVisible);
    };
    const handleFormFormPropSubmit = (formData) => {
        setFormProperties(formData);
        //saveData("formRowsProps", formData);
        onPropertiesChange(formData);
        toggleFormPropVisible();
    };
    //#endregion

    const handleFormBuilderFormFinish = (values) => {
        message.info("See values on console");
        console.log(values);
    };

    const BuilderContextMenu = (formRowId, formRowColId) => (
        <Menu theme="light">
            <Menu.ItemGroup title="Columns">
                <Menu.Item
                    onClick={() => showFormItems(formRowId, formRowColId)}
                >
                    Manage Items
                </Menu.Item>
                <Menu.Item
                    onClick={() => showFormColProp(formRowId, formRowColId)}
                >
                    Column Properties
                </Menu.Item>
                <Menu.SubMenu title="Column Actions">
                    <Menu.Item
                        onClick={() =>
                            addFormRowCol(formRowId, formRowColId, "before")
                        }
                    >
                        Insert Column Before
                    </Menu.Item>
                    <Menu.Item
                        onClick={() =>
                            addFormRowCol(formRowId, formRowColId, "after")
                        }
                    >
                        Insert Column After
                    </Menu.Item>
                    <Menu.Item
                        onClick={() =>
                            deleteFormRowCol(formRowId, formRowColId)
                        }
                    >
                        Delete Column
                    </Menu.Item>
                    <Menu.Item onClick={() => showFormMoveCol(formRowId)}>
                        Move Columns
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Rows">
                <Menu.Item onClick={() => showFormRowProp(formRowId)}>
                    Row Properties
                </Menu.Item>
                <Menu.SubMenu title="Row Actions">
                    <Menu.Item onClick={() => addFormRow(formRowId, "before")}>
                        Insert Row Before
                    </Menu.Item>
                    <Menu.Item onClick={() => addFormRow(formRowId, "after")}>
                        Insert Row After
                    </Menu.Item>
                    <Menu.Item onClick={() => deleteFormRow(formRowId)}>
                        Delete Row
                    </Menu.Item>
                    <Menu.Item onClick={toggleFormMoveRowVisible}>
                        Move Rows
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Form">
                <Menu.Item onClick={toggleFormPropVisible}>
                    Form Properties
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );

    return (
        <>
            <Alert
                message={
                    <Row type="flex" justify="space-between">
                        <Col>
                            <Space size="middle">
                                <span>
                                    Selected Row:{" "}
                                    {selectedFormRow
                                        ? selectedFormRow.name
                                        : "None"}
                                </span>
                                <span>
                                    Selected Column:{" "}
                                    {selectedFormRowCol
                                        ? selectedFormRowCol.name
                                        : "None"}
                                </span>
                            </Space>
                        </Col>
                        <Col>
                            <Popover
                                content={
                                    <div>
                                        <div>ALT + R = add row</div>
                                        <div>ALT + C = add col</div>
                                        <div>ALT + SHIFT + R = del row</div>
                                        <div>ALT + SHIFT + C = del col</div>
                                        <div>ALT + W = arrage rows</div>
                                        <div>ALT + Q = arrange cols</div>
                                        <div>ALT + A = manage items</div>
                                        <Alert
                                            message="you need to select column in order to use keys"
                                            showIcon
                                            style={{ marginTop: 10 }}
                                        />
                                    </div>
                                }
                                title="Shortcut Keys"
                                placement="bottomLeft"
                            >
                                <QuestionCircleOutlined />
                            </Popover>
                        </Col>
                    </Row>
                }
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
            />

            <Card>
                <Form
                    {...formProperties.layout}
                    form={form}
                    onFinish={handleFormBuilderFormFinish}
                >
                    {formRows.map((formRow) => (
                        <Tooltip
                            key={formRow.id}
                            title={formRow.name}
                            placement="left"
                        >
                            <div style={CSSToReactStyle(formRow.style)}>
                                <Row className="formBuilderRow">
                                    {formRow.cols.map((formRowCol) => (
                                        <Tooltip
                                            key={formRowCol.id}
                                            title={formRowCol.name}
                                        >
                                            <Dropdown
                                                overlay={BuilderContextMenu(
                                                    formRow.id,
                                                    formRowCol.id
                                                )}
                                                trigger={["contextMenu"]}
                                            >
                                                <Col
                                                    xl={formRowCol.width.xl}
                                                    lg={formRowCol.width.lg}
                                                    md={formRowCol.width.md}
                                                    sm={formRowCol.width.sm}
                                                    xs={formRowCol.width.xs}
                                                    className="formBuilderCol"
                                                    onClick={() =>
                                                        showSelected(
                                                            formRow.id,
                                                            formRowCol.id
                                                        )
                                                    }
                                                    onDoubleClick={() =>
                                                        showFormColProp(
                                                            formRow.id,
                                                            formRowCol.id
                                                        )
                                                    }
                                                >
                                                    <div
                                                        style={CSSToReactStyle(
                                                            formRowCol.style
                                                        )}
                                                    >
                                                        {formRowCol.formItems
                                                            .length === 0 ? (
                                                            <span>&nbsp;</span>
                                                        ) : (
                                                            <FormItemRender
                                                                formItems={
                                                                    formRowCol.formItems
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </Col>
                                            </Dropdown>
                                        </Tooltip>
                                    ))}
                                </Row>
                            </div>
                        </Tooltip>
                    ))}
                    {formProperties.submitButton.show && (
                        <div
                            style={
                                formProperties.submitButton.containerStyle
                                    ? {
                                          ...CSSToReactStyle(
                                              formProperties.submitButton
                                                  .containerStyle
                                          ),
                                          width: "100%",
                                      }
                                    : null
                            }
                        >
                            <Form.Item noStyle={true}>
                                <Button
                                    type="primary"
                                    size={formProperties.submitButton.size}
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form>
            </Card>

            <Modal
                title="Row Properties"
                visible={isFormRowPropVisible}
                onCancel={toggleFormRowPropVisible}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <FormRowProperties
                    formData={selectedFormRow}
                    onFinish={handleFormRowPropertiesFinish}
                />
            </Modal>

            <Modal
                title="Move Rows"
                visible={isFormMoveRowVisible}
                onCancel={toggleFormMoveRowVisible}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <FormMoveRows formRows={formRows} onSortOk={sortFormRows} />
            </Modal>

            <Modal
                title="Column Properties"
                visible={isFormColPropVisible}
                onCancel={toggleFormColPropVisible}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <FormColProperties
                    formData={selectedFormRowCol}
                    onFinish={handleFormColPropertiesFinish}
                />
            </Modal>

            <Modal
                title="Move Columns"
                visible={isFormMoveColVisible}
                onCancel={toggleFormMoveColVisible}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <FormMoveCols
                    formRow={selectedFormRow}
                    onSortOk={sortFormRowCols}
                />
            </Modal>

            <Modal
                title="Manage Column Items"
                visible={isFormManageItemsVisible}
                onCancel={toggleFormManageItemsVisible}
                width={900}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <FormManageColItems
                    formRows={formRows}
                    selectedFormRowCol={selectedFormRowCol}
                    onFinish={handleFormRowColItemsFinish}
                />
            </Modal>

            <Modal
                title="Form Properties"
                visible={isFormPropVisible}
                onCancel={toggleFormPropVisible}
                //width={900}
                footer={null}
                style={{ top: 20 }}
                destroyOnClose
            >
                <FormFormProperties
                    formProperties={formProperties}
                    onFinish={handleFormFormPropSubmit}
                />
            </Modal>
        </>
    );
};

export default FormRenderBuilder;
