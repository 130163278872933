import { combineReducers } from "redux";
import systemReducer from "./system/system.reducer";
import clinicReducer from "./clinics/clinics.reducer";

const RootReducer = combineReducers({
    System: systemReducer,
    Clinics: clinicReducer,
});

export default RootReducer;
