import React from "react";
import { Table, Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
//import { systemStoragePath } from "config.json";

const ConversationList = (props) => {
    const { conversations, currentUserID, serverDate, onChange } = props;

    const converstationTableColumns = [
        {
            key: "conversation",
            className: "cursorPointer",
            render: (text, record) => {
                const {
                    //img_path,
                    name,
                    clinic_name,
                    recentMessage,
                } = record;

                // const avatarProps = img_path
                //     ? {
                //           size: 40,
                //           src: `${systemStoragePath}/pictures/${img_path}`
                //       }
                //     : { size: 40, icon: "user" };
                const avatarProps = { size: 40, icon: <UserOutlined /> };

                let displayTime = "";
                if (recentMessage) {
                    const { datetime_sent } = recentMessage;
                    if (
                        moment(datetime_sent).isSame(moment(serverDate), "day")
                    ) {
                        displayTime = moment(datetime_sent).format("hh:mm A");
                    } else if (
                        moment(datetime_sent).isSame(moment(serverDate), "week")
                    ) {
                        displayTime = moment(datetime_sent).format("ddd");
                    } else if (
                        moment(datetime_sent).isSame(moment(serverDate), "year")
                    ) {
                        displayTime = moment(datetime_sent).format("MMM DD");
                    } else {
                        displayTime = moment(datetime_sent).format("MM/DD/YY");
                    }
                }

                return (
                    <div>
                        <div
                            style={{
                                float: "left",
                                marginRight: 10,
                            }}
                        >
                            <Avatar {...avatarProps} />
                        </div>
                        <div
                            style={{
                                float: "left",
                                maxWidth: 320,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            <div>
                                {name}
                                <br />({clinic_name})
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontWeight:
                                            recentMessage &&
                                            recentMessage.is_read === 0 &&
                                            recentMessage.sender_id !==
                                                currentUserID
                                                ? "bold"
                                                : "normal",
                                    }}
                                >
                                    {recentMessage ? (
                                        <span>
                                            {`${recentMessage.message.substring(
                                                0,
                                                13
                                            )}`}
                                            &hellip; &middot; {`${displayTime}`}
                                        </span>
                                    ) : (
                                        "Start Conversation"
                                    )}
                                </span>
                            </div>
                            <div>{}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "totalUnread",
            width: 50,
            className: "cursorPointer",
            render: (text, record) => (
                <Badge count={record.totalUnreadMessage} />
            ),
        },
    ];

    return (
        <>
            <Table
                columns={converstationTableColumns}
                dataSource={conversations.map((conversation, index) => ({
                    ...conversation,
                    id: index,
                }))}
                pagination={false}
                showHeader={false}
                rowKey="id"
                onRow={(record) => ({
                    onClick: () => {
                        onChange(record);
                    },
                })}
            />
        </>
    );
};

export default ConversationList;
