import React from "react";
import { Typography } from "antd";
import Prism from "prismjs";
import ReactHtmlParser from "react-html-parser";

const DataList = () => {
    const { Title } = Typography;

    const generalDetailsCheatSheet = [
        `<span data-role="currentDate" data-lookup="general">currentDate</span>`,
    ];

    const patientDetailsCheatSheet = [
        `<span data-role="img_path" data-lookup="patient">img_path</span>`,
        `<span data-role="fullname" data-lookup="patient">fullname</span>`,
        `<span data-role="firstname" data-lookup="patient">firstname</span>`,
        `<span data-role="middlename" data-lookup="patient">middlename</span>`,
        `<span data-role="lastname" data-lookup="patient">lastname</span>`,
        `<span data-role="age" data-lookup="patient">age</span>`,
        `<span data-role="birthday" data-lookup="patient">birthday</span>`,
        `<span data-role="gender" data-lookup="patient">gender</span>`,
        `<span data-role="civil_status" data-lookup="patient">civil_status</span>`,
        `<span data-role="mobile" data-lookup="patient">mobile</span>`,
        `<span data-role="nickname" data-lookup="patient">nickname</span>`,
        `<span data-role="full_address" data-lookup="patient">full_address</span>`,
        `<span data-role="street" data-lookup="patient">street</span>`,
        `<span data-role="town_city" data-lookup="patient">town_city</span>`,
        `<span data-role="province" data-lookup="patient">province</span>`,
        `<span data-role="zip" data-lookup="patient">zip</span>`,
    ];

    const doctorDetailsCheatSheet = [
        `<span data-role="img_path" data-lookup="doctor">img_path</span>`,
        `<span data-role="doctor_name" data-lookup="doctor">doctor_name</span>`,
        `<span data-role="degree" data-lookup="doctor">degree</span>`,
        `<span data-role="license_number" data-lookup="doctor">license_number</span>`,
        `<span data-role="ptr" data-lookup="doctor">ptr</span>`,
        `<span data-role="area_of_practice" data-lookup="doctor">area_of_practice</span>`,
        `<span data-role="signature" data-lookup="doctor">signature</span>`,
    ];

    const clinicsDetailsCheatSheet = [
        `<span data-role="img_path" data-lookup="clinic">img_path</span>`,
        `<span data-role="name" data-lookup="clinic">clinic_name</span>`,
        `<span data-role="address" data-lookup="clinic">clinic_address</span>`,
        `<span data-role="email" data-lookup="clinic">email</span>`,
        `<span data-role="mobile" data-lookup="clinic">mobile</span>`,
        `<span data-role="schedule" data-lookup="clinic">schedule</span>`,
        `<span data-role="telephone" data-lookup="clinic">telephone</span>`,
        `<span data-role="website" data-lookup="clinic">website</span>`,
        `<span data-role="description" data-lookup="clinic">description</span>`,
    ];

    const caseDetailsCheatSheet = [
        `<span data-role="complaint_datetime" data-lookup="case">complaint_datetime</span>`,
    ];

    return (
        <div style={{ height: 351, overflow: "auto", whiteSpace: "nowrap" }}>
            <Title level={4}>General</Title>
            <div style={{ marginBottom: 16 }}>
                {generalDetailsCheatSheet.map((item, index) => {
                    return (
                        <div key={index}>
                            {ReactHtmlParser(
                                Prism.highlight(
                                    item,
                                    Prism.languages.html,
                                    "html"
                                )
                            )}
                        </div>
                    );
                })}
            </div>
            <Title level={4}>Patient</Title>
            <div style={{ marginBottom: 16 }}>
                {patientDetailsCheatSheet.map((item, index) => {
                    return (
                        <div key={index}>
                            {ReactHtmlParser(
                                Prism.highlight(
                                    item,
                                    Prism.languages.html,
                                    "html"
                                )
                            )}
                        </div>
                    );
                })}
            </div>
            <Title level={4}>Doctor</Title>
            <div style={{ marginBottom: 16 }}>
                {doctorDetailsCheatSheet.map((item, index) => {
                    return (
                        <div key={index}>
                            {ReactHtmlParser(
                                Prism.highlight(
                                    item,
                                    Prism.languages.html,
                                    "html"
                                )
                            )}
                        </div>
                    );
                })}
            </div>
            <Title level={4}>Clinic</Title>
            <div style={{ marginBottom: 16 }}>
                {clinicsDetailsCheatSheet.map((item, index) => {
                    return (
                        <div key={index}>
                            {ReactHtmlParser(
                                Prism.highlight(
                                    item,
                                    Prism.languages.html,
                                    "html"
                                )
                            )}
                        </div>
                    );
                })}
            </div>
            <Title level={4}>Case</Title>
            <div style={{ marginBottom: 16 }}>
                {caseDetailsCheatSheet.map((item, index) => {
                    return (
                        <div key={index}>
                            {ReactHtmlParser(
                                Prism.highlight(
                                    item,
                                    Prism.languages.html,
                                    "html"
                                )
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DataList;
