import { SET_CLINICS_SUCCESS } from "./clinics.types";

const INIT_STATE = [];

const hmoReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CLINICS_SUCCESS:
            return action.payload.clinics;
        default:
            return state;
    }
};

export default hmoReducer;
