import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import RSEEditor from "react-simple-code-editor";
import Prism from "prismjs";

const FormRowProperties = ({ formData, onFinish }) => {
    const [editorValue, setEditorValue] = useState(formData.style);
    const [form] = Form.useForm(null);

    useEffect(() => {
        form.setFieldsValue({
            name: formData.name,
            style: formData.style,
        });
        setEditorValue(formData.style);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const handleEditorValueChange = (value) => {
        setEditorValue(value);
    };

    const handleFormFinish = (values) => {
        onFinish({
            ...values,
            style: editorValue,
        });
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    return (
        <Form {...layout} form={form} onFinish={handleFormFinish}>
            <Form.Item
                label="Row Name"
                name="name"
                rules={[{ required: true, message: "Please input row name!" }]}
            >
                <Input />
            </Form.Item>
            <Form.Item label="Style">
                <RSEEditor
                    textareaClassName="htmlCSSEditor"
                    padding={10}
                    value={editorValue}
                    onValueChange={handleEditorValueChange}
                    highlight={(code) =>
                        Prism.highlight(code, Prism.languages.css, "css")
                    }
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        border: "1px solid #ccc",
                    }}
                />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FormRowProperties;
