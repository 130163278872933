import React, { useState, useEffect, useRef } from "react";
import {
    Layout,
    Row,
    Col,
    Card,
    Form,
    Button,
    message,
    Input,
    Typography,
    Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import http from "services/httpService";
import { apiUrl, appBasePath, platform } from "config.json";

const Login = (props) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [form] = Form.useForm();

    const txtUsername = useRef(null);

    const { Title } = Typography;

    useEffect(() => {
        txtUsername.current.focus();
        const isUserLogin = localStorage.getItem("token") ? true : false;
        if (isUserLogin) {
            return props.history.push(`/dashboard`);
        }
    }, [props.history]);

    const handleSubmit = async (formData) => {
        setIsAuthenticating(true);
        try {
            const response = await http.post(`${apiUrl}/signin`, formData);
            const { isSignInError, signInErrorMsg, token } = response.data;
            if (isSignInError) {
                form.setFields([
                    {
                        name: "username",
                        errors: [signInErrorMsg],
                    },
                ]);
                setIsAuthenticating(false);
            } else {
                localStorage.setItem("token", token);
                if (platform === "web") {
                    window.location = `${appBasePath}/dashboard`;
                } else {
                    window.location.reload();
                }
            }
        } catch (error) {
            message.error(error.message || "Something went wrong");
            setIsAuthenticating(false);
        }
    };

    return (
        <Layout>
            <Row
                type="flex"
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xl={8} md={8} xs={1} />
                <Col xl={8} md={8} xs={22}>
                    <Card
                        style={{
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "block",
                                textAlign: "center",
                            }}
                        >
                            <Avatar
                                style={{ backgroundColor: "#0288d1" }}
                                icon={<UserOutlined />}
                                size="large"
                            />
                            <Title level={4}>Admin Login</Title>
                        </div>
                        <div
                            style={{
                                maxHeight: 320,
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={handleSubmit}
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your username!",
                                        },
                                    ]}
                                >
                                    <Input ref={txtUsername} />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please input your password!",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        loading={isAuthenticating}
                                    >
                                        {isAuthenticating
                                            ? "Signing In"
                                            : "Sign In"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Card>
                </Col>
                <Col xl={8} md={8} xs={1} />
            </Row>
        </Layout>
    );
};

export default Login;
